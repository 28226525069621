import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function actualizarMedicine(accessToken, data, medicineId) {
  const response = await fetch(`${URL}/medicines/${medicineId}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return { response, res };
  }
}
