import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
  Box,
  Modal,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddDoctorIcon from "assets/icons/AddDoctoricon.svg";
import { postPatientRequest } from "utils/post";
import { useAlert } from "contexts/AlertContext";

const PREFIX = "Overview-CardTratantes";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({}));

const CardTratantes = (props) => {
  const rootClasses = props.rootClasses;
  const userData = props.userData;
  const setIsSuccessModalOpen = props.setIsSuccessModalOpen;
  const setSuccessMessage = props.setSuccessMessage;
  const attendants = props.attendants;
  const availableProfessionals = props.availableProfessionals;
  const role = localStorage.getItem("role");

  const [isDoctorModalOpen, setIsDoctorModalOpen] = useState(false); // State for doctor modal visibility
  const [selectedDoctorID, setSelectedDoctorID] = useState("");
  const { showError } = useAlert();

  const handleOpenDoctorModal = () => {
    setIsDoctorModalOpen(true);
  };

  const handleCloseDoctorModal = () => {
    setIsDoctorModalOpen(false);
    // Removed success message logic
  };

  const handleSendDoctorRequest = async () => {
    // Validar si el usuario no es un paciente
    if (role !== "patient") {
      showError("Solo un paciente puede hacer esta acción.");
      return;
    }

    if (!selectedDoctorID) {
      showError("Por favor selecciona un médico.");
      return;
    }

    try {
      const response = await postPatientRequest(userData.id, selectedDoctorID);
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }
      setIsDoctorModalOpen(false);
      setSuccessMessage("Tu solicitud de agregar médico ha sido enviada.");
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Failed to send doctor request:", error);
      showError(
        "No se pudo enviar la solicitud. \n- Si ya has enviado una solicitud, por favor espera a que sea aceptada.",
      );
    }
  };

  const filteredAvailableProfessionals = availableProfessionals.filter(
    (professional) =>
      !attendants.some((attendant) => attendant.id === professional.id),
  );

  return (
    <Root className={classes.root}>
      <Card className={rootClasses.card}>
        <CardHeader
          title={<Typography variant="h3">Mis médicos tratantes</Typography>}
        />
        <Divider />
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {attendants.map((doctor) => (
              <Typography key={doctor.id} variant="body1">
                {doctor.first_name} {doctor.last_name} -{" "}
                <strong>
                  {doctor.specialty_name || "Especialidad no especificada"}
                </strong>
              </Typography>
            ))}
          </Box>
          <Button
            className={rootClasses.button}
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
            sx={{ marginTop: 2 }}
            onClick={handleOpenDoctorModal} // Add this line
          >
            Agregar médico tratante
          </Button>
        </CardContent>
      </Card>
      <Modal open={isDoctorModalOpen} onClose={handleCloseDoctorModal}>
        <Box
          style={{
            width: 400,
            margin: "auto",
            marginTop: "10%",
            padding: "24px",
            backgroundColor: "white",
            borderRadius: 24,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center", // Center the text
          }}
        >
          <img
            src={AddDoctorIcon}
            alt="Add Doctor"
            style={{ width: 50, marginBottom: 16 }}
          />
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", fontSize: 18 }}
          >
            Agrega un nuevo médico tratante
          </Typography>
          <Typography sx={{ mt: 2, mb: 3 }}>
            El médico tratante recibirá tu solicitud y deberá aceptarla. Con
            esto estás dando acceso al médico a tus datos personales.
          </Typography>
          <Autocomplete
            fullWidth
            options={filteredAvailableProfessionals}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            value={
              filteredAvailableProfessionals.find(
                (prof) => prof.id === selectedDoctorID,
              ) || null
            }
            onChange={(event, newValue) => {
              setSelectedDoctorID(newValue ? newValue.id : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Buscar médico"
                placeholder="Escribe el nombre del médico"
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: "200px", // Adjust this value to control scroll area
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSendDoctorRequest} // Updated to use the new function
            sx={{ mt: 2, borderRadius: "16px" }}
          >
            Enviar solicitud
          </Button>
        </Box>
      </Modal>
    </Root>
  );
};

CardTratantes.propTypes = {
  rootClasses: PropTypes.object,
  userData: PropTypes.object,
  setIsSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  attendants: PropTypes.array,
  availableProfessionals: PropTypes.array,
};

export default CardTratantes;
