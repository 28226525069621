import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function favoritePatientTask(ptId, favorite) {
  const accessToken = localStorage.getItem("currentToken");
  const response = await fetch(`${URL}/patientTasks/${ptId}/favorite`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      favorite,
    }),
  });
  const res = await response;
  if (checkResponse(response)) {
    return res;
  }
}
