import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { postSummary, postOverview } from "utils/post";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  Card,
  CardHeader,
  colors,
  IconButton,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { Grid } from "@mui/material";
import palette from "../../../theme/palette";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FilterList } from "@mui/icons-material"; // Import a filter icon
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const PREFIX = "PainBarGraph";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  markdownContainer: `${PREFIX}-markdownContainer`,
  headerTitle: `${PREFIX}-headerTitle`,
  formControl: `${PREFIX}-formControl`,
  inputLabel: `${PREFIX}-inputLabel`,
  filterMenu: `${PREFIX}-filterMenu`, // New class for the filter menu
  radioGroup: `${PREFIX}-radioGroup`, // New class for the radio group
  radioOption: `${PREFIX}-radioOption`, // New class for each radio option
  radioButton: `${PREFIX}-radioButton`,
  menuButton: `${PREFIX}-menuButton`, // New class for the radio button
};

const StyledBarContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default, // White semi-transparent background
  padding: theme.spacing(2, 5), // Padding around the chart
  borderRadius: theme.shape.borderRadius, // Rounded corners
  boxShadow: theme.shadows[0], // Add shadow for a nice effect
  width: "100%", // Make the container take the full width of its parent
  maxWidth: "1200px", // Set a maximum width to make the x-axis longer
  margin: "0 auto", // Center the container
}));
const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "24px", // Use theme borderRadius
    boxShadow: theme.shadows[4], // Add shadow to the Paper component
    padding: theme.spacing(2), // Add padding inside the menu
    backgroundColor: theme.palette.background.paper, // Menu background color
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(1),
  ".MuiTypography-root": {
    fontWeight: "bold", // Bold text
    fontSize: "1rem", // Consistent font size
    color: theme.palette.text.primary, // Use theme primary text color
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(0),
    borderRadius: "24px",
    backgroundColor: palette.background.paper,
  },

  [`& .${classes.divider}`]: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    borderRadius: "12px",
  },

  [`& .${classes.markdownContainer}`]: {
    maxWidth: 700,
  },

  [`& .${classes.headerTitle}`]: {
    fontSize: 20,
    fontWeight: "bold",
    color: palette.text.primary,
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.menuButton}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light, // Set the icon button to blue

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const PainBarGraph = (props) => {
  const [time, setTime] = useState("all");
  const [isActive, setIsActive] = useState(false);

  const metric = "pain"; // "pain", "goals", "fatigue" -> metricas con un valor
  const accessToken = localStorage.getItem("currentToken");
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  const dataValues = {
    labels: [],
    values: [],
    max: 10,
  };

  const [booleano, setBooleano] = useState(false);
  const [graphData, setGraphData] = useState(dataValues);

  async function fetchData(time_value) {
    if (role === "admin") {
      var answers = await postOverview(accessToken, time_value);
    } else {
      var answers = await postSummary(accessToken, id, time_value);
    }
    const valuesAnswers = answers["stats"][metric]["data"];
    const max = answers["stats"][metric]["answers"];

    const labels = [];
    const values = [];

    for (const [key, value] of Object.entries(valuesAnswers)) {
      labels.unshift(key);
      values.unshift(value);
    }

    const result = {
      labels: labels,
      values: values,
      max: max,
    };
    setGraphData(result);
    setBooleano(true);
    return answers;
  }

  const data = {
    labels: graphData.labels,
    datasets: [
      {
        label: "cantidad de pacientes por nivel de dolor",
        data: graphData.values,
        backgroundColor: palette.graph.error.light, // Lighter color for the center
        borderColor: palette.graph.error.dark, // Darker color for the borders
        borderWidth: 2,
        borderRadius: 6,
        fill: false,
      },
    ],
  };

  const options = {
    indexAxis: "x", // Esto cambia la orientación del gráfico
    scales: {
      x: {
        beginAtZero: true,
        min: 0,
        max: graphData.max,
        reverse: true, // Reverse the x-axis to start from 10 and go to 0
        grid: {
          display: false, // Remove the background grid
        },
      },
      y: {
        beginAtZero: true, // Ensure y-axis starts at 0
        ticks: {
          stepSize: 1, // Ensure y-axis only displays integers
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
        },
        grid: {
          display: false, // Remove the background grid
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    maintainAspectRatio: false,
  };

  useEffect(() => {
    fetch("/docs/GettingStarted.md").then((response) => response.text());
    if (!booleano) fetchData(time);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsActive((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleMenuItemClick = async (value) => {
    setTime(value);
    fetchData(value);
    handleClose();
    setIsActive(false);
  };

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        // action={<GenericMoreButton />}
        title="Resumen del Dolor"
        classes={{
          title: classes.headerTitle,
        }}
        action={
          <IconButton
            className={`${classes.menuButton} ${isActive ? "active" : ""}`}
            onClick={handleClick}
          >
            <FilterList />
          </IconButton>
        }
      />
      <Divider />
      <Grid container justify="flex-end">
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography variant="overline" sx={{ marginLeft: 2 }}>
            Filtros por tiempo
          </Typography>
          <Divider />
          <StyledRadioGroup
            value={time}
            onChange={(event) => handleMenuItemClick(event.target.value)}
          >
            <StyledFormControlLabel
              className={classes.radioOption} // Use class for radio option
              value="last-week"
              control={<Radio className={classes.radioButton} />} // Use class for radio button
              label="Última semana"
            />
            <StyledFormControlLabel
              className={classes.radioOption}
              value="last-month"
              control={<Radio className={classes.radioButton} />}
              label="Último mes"
            />
            <StyledFormControlLabel
              className={classes.radioOption}
              value="last3"
              control={<Radio className={classes.radioButton} />}
              label="Últimos 3 meses"
            />
            <StyledFormControlLabel
              className={classes.radioOption}
              value="all"
              control={<Radio className={classes.radioButton} />}
              label="Todo el tiempo"
            />
          </StyledRadioGroup>
        </StyledMenu>
      </Grid>
      <Typography gutterBottom variant="overline"></Typography>
      <StyledBarContainer>
        <div style={{ width: "100%", height: "400px" }}>
          {" "}
          {/* Adjust the height as needed */}
          <Bar data={data} options={options} />
        </div>
      </StyledBarContainer>
    </StyledCard>
  );
};
PainBarGraph.propTypes = {
  className: PropTypes.string,
};

export default PainBarGraph;
