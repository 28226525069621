import React, { useState, forwardRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ListItem, Button, Collapse, colors, Avatar } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  getUnreadCount,
  getPendingRequests,
  getCrisisCount,
} from "../../../../utils/fetch";

const PREFIX = "NavigationListItem";

const classes = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  button: `${PREFIX}-button`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  icon: `${PREFIX}-icon`,
  expandIcon: `${PREFIX}-expandIcon`,
  newItems: `${PREFIX}-newItems`,
  active: `${PREFIX}-active`,
  warningIcon: `${PREFIX}-warningIcon`,
  messageCounter: `${PREFIX}-messageCounter`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    display: "flex",
    paddingTop: 3,
    paddingBottom: 3,
  },

  [`& .${classes.itemLeaf}`]: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.button}`]: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`& .${classes.buttonLeaf}`]: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.expandIcon}`]: {
    marginLeft: "auto",
    height: 16,
    width: 16,
  },

  [`& .${classes.newItems}`]: {
    marginLeft: theme.spacing(11),
  },

  [`& .${classes.active}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 12,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: "#802e87",
    },
  },

  [`& .${classes.warningIcon}`]: {
    backgroundColor: colors.red[600],
    height: 16,
    width: 16,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.messageCounter}`]: {
    backgroundColor: colors.red[600],
    height: 20,
    width: 20,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const NavigationListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    className,
    open: openProp = false, // Valor por defecto para `open`
    ...rest
  } = props;

  const location = useLocation();

  const [open, setOpen] = useState(openProp);
  const userData = useSelector((state) => state.userData.serverUserData);
  const currentUserId = parseInt(localStorage.getItem("id"));
  const [pendingRequests, setPendingRequests] = useState(() =>
    parseInt(localStorage.getItem("pendingRequestsCount") || "0"),
  );
  const [crisisCount, setCrisisCount] = useState(() =>
    parseInt(localStorage.getItem("crisisCount") || "0"),
  );
  const [unreadCount, setUnreadCount] = useState(() =>
    parseInt(localStorage.getItem("unreadCount") || "0"),
  );

  useEffect(() => {
    const fetchPendingRequests = async () => {
      if (userData.role === "professional") {
        const { res } = await getPendingRequests(currentUserId);
        const newCount = res.requests || 0;
        setPendingRequests((prev) => {
          if (newCount !== prev) {
            localStorage.setItem("pendingRequestsCount", newCount.toString());
            return newCount;
          }
          return prev;
        });
      }
    };
    fetchPendingRequests();
  }, [currentUserId, userData]);

  useEffect(() => {
    const fetchCrisisCount = async () => {
      if (userData.role === "professional") {
        const accessToken = localStorage.getItem("currentToken");
        const count = await getCrisisCount(accessToken);
        setCrisisCount((prev) => {
          if (count !== prev) {
            localStorage.setItem("crisisCount", count.toString());
            return count;
          }
          return prev;
        });
      }
    };
    fetchCrisisCount();
  }, [currentUserId, userData]);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      const { res } = await getUnreadCount(currentUserId);
      const newCount = res.unreadCount || 0;
      setUnreadCount((prev) => {
        if (newCount !== prev) {
          localStorage.setItem("unreadCount", newCount.toString());
          return newCount;
        }
        return prev;
      });
    };
    fetchUnreadCount();
  }, [currentUserId]);

  const hasUnReadAlerts =
    userData?.profile?.unread_messages > 0 ||
    userData?.profile?.unread_alerts > 0;

  const handleToggle = () => {
    setOpen(!open);
  };

  const getPaddingLeft = (depth) => 8 + 24 * depth; // Simplificación de `paddingLeft`

  const renderWarnings = () => {
    if (hasUnReadAlerts && title === "Avisos") {
      return <Avatar className={classes.warningIcon}>!</Avatar>;
    }

    if (title === "Autorreporte") {
      const answeredToday = localStorage.getItem("answered_today") === "true";
      return !answeredToday ? <Avatar className={classes.warningIcon}>!</Avatar> : null;
    }

    if (
      title === "Mensajería" &&
      unreadCount > 0 &&
      !location.pathname.includes("/mensajeria")
    ) {
      return <Avatar className={classes.messageCounter}>{unreadCount}</Avatar>;
    }

    if (title === "Mis Pacientes" && pendingRequests > 0) {
      return (
        <Avatar className={classes.messageCounter}>{pendingRequests}</Avatar>
      );
    }

    if (title === "Crisis" && crisisCount > 0) {
      return <Avatar className={classes.messageCounter}>{crisisCount}</Avatar>;
    }

    return null;
  };

  if (children) {
    return (
      <StyledListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={{ paddingLeft: getPaddingLeft(depth) }}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  } else {
    return (
      <StyledListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          style={{ paddingLeft: getPaddingLeft(depth) }}
          to={href}
        >
          {Icon && <Icon className={classes.icon} />}
          {title} {renderWarnings()}
        </Button>
      </StyledListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType, // Ajustar a `elementType` para componentes de íconos
  label: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default NavigationListItem;
