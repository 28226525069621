import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { AlertModal } from "../../components";
import { getAlertToday } from "utils/fetch/getAlertToday";

const PREFIX = "Overview-CardCrisis";

const classes = {
  root: `${PREFIX}-root`,
  crisisTitle: `${PREFIX}-crisisTitle`,
  crisisButton: `${PREFIX}-crisisButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.crisisTitle}`]: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.error.main, // This will use the theme's error color (usually red)
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.crisisButton}`]: {
    marginTop: theme.spacing(2),
    borderRadius: "24px",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const CardCrisis = (props) => {
  const rootClasses = props.rootClasses;
  const userData = props.userData;
  const setIsSuccessModalOpen = props.setIsSuccessModalOpen;
  const setSuccessMessage = props.setSuccessMessage;
  const attendants = props.attendants;

  const theme = useTheme(); // Add this line to get the theme object
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertExists, setAlertExists] = useState(false);

  useEffect(() => {
    const checkAlertStatus = async () => {
      try {
        const userId = localStorage.getItem("id");
        const alertStatus = await getAlertToday(userId);
        setAlertExists(alertStatus.alert_exists);
      } catch (error) {
        console.error("Error checking alert status:", error);
      }
    };

    checkAlertStatus();
  }, []); // Empty dependency array means this runs once when component mounts

  const handleCloseAlertModal = () => {
    setAlertModalOpen(false);
  };

  const handleAlertSuccess = async () => {
    setAlertModalOpen(false);
    setSuccessMessage("Alerta enviada con éxito");
    setIsSuccessModalOpen(true);
    setAlertExists(true); // Update the local state immediately

    // Re-check alert status from server
    try {
      const userId = localStorage.getItem("id");
      const alertStatus = await getAlertToday(userId);
      setAlertExists(alertStatus.alert_exists);
    } catch (error) {
      console.error("Error updating alert status:", error);
    }
  };

  const handleOpenAlertModal = async () => {
    if (alertExists) {
      return; // Don't open modal if alert exists
    }
    setAlertModalOpen(true);
  };

  return (
    <Root className={classes.root}>
      <Card className={rootClasses.card}>
        <CardContent className={rootClasses.cardContent}>
          <Typography
            variant="h5"
            component="h2"
            className={classes.crisisTitle}
          >
            ¿Tienes una crisis {userData.first_name}?
          </Typography>
          <Typography color="textSecondary">
            {alertExists
              ? "Ya has reportado una crisis hoy. Nuestro equipo médico ha sido notificado."
              : "Reporta tu crisis para recibir ayuda inmediata"}
          </Typography>
          <Button
            className={classes.crisisButton}
            variant="contained"
            startIcon={<LocalHospitalIcon />}
            onClick={handleOpenAlertModal}
            disabled={alertExists}
            sx={{
              ...(alertExists && {
                backgroundColor: theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: theme.palette.grey[700],
                },
              }),
            }}
          >
            {alertExists ? "Crisis Reportada" : "Reportar Crisis"}
          </Button>
        </CardContent>
      </Card>
      <AlertModal
        open={alertModalOpen}
        onClose={handleCloseAlertModal}
        onSuccess={handleAlertSuccess}
        attendants={attendants}
      />
    </Root>
  );
};

CardCrisis.propTypes = {
  rootClasses: PropTypes.object,
  userData: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setIsSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  attendants: PropTypes.array,
};

export default CardCrisis;
