import React, { Fragment, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { Button, Link, Typography, colors } from "@mui/material";
// import { useSelector } from 'react-redux';
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axios from "axios";
import refreshPage from "utils/refreshPage";
import { useAlert } from "contexts/AlertContext";

const PREFIX = "ProfilePhoto";

const classes = {
  root: `${PREFIX}-root`,
  dropZone: `${PREFIX}-dropZone`,
  dragActive: `${PREFIX}-dragActive`,
  image: `${PREFIX}-image`,
  info: `${PREFIX}-info`,
  list: `${PREFIX}-list`,
  actions: `${PREFIX}-actions`,
  shareIcon: `${PREFIX}-shareIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.dropZone}`]: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(1),
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer",
    },
  },

  [`& .${classes.dragActive}`]: {
    backgroundColor: colors.grey[50],
    opacity: 0.5,
  },

  [`& .${classes.image}`]: {
    width: 100,
  },

  [`& .${classes.info}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.list}`]: {
    maxHeight: 200,
  },

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },

  [`& .${classes.shareIcon}`]: {
    marginRight: theme.spacing(1),
  },
}));

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 10,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const ProfilePhoto = (props) => {
  const { className, URL1, currentToken, setLoading, ...rest } = props;
  const { showError, showSuccess } = useAlert();

  const [files, setFiles] = useState([]);
  //const currentToken = useSelector(state => state.auth.currentToken)

  function uploadPhotos() {
    setLoading(true);
    if (files.length > props.MAX_FILES) {
      showError("Límite de fotos alcanzado!");
      handleRemoveAll();
      return false;
    }

    let config = {
      headers: {
        Authorization: "Token " + currentToken,
      },
    };

    const data = new FormData();
    data.append("file", files[0]);

    try {
      axios.post(URL1, data, config).then((res) => {
        if (res.status === 200) {
          showSuccess("Foto de perfil cargada con éxito");
          setLoading(false);
          refreshPage();
        } else {
          showError("Error", res.status);
          setLoading(false);
        }
      });
    } catch (error) {
      showError("Hubo un error", error);
      setLoading(false);
    }

    handleRemoveAll();
    return true;
  }

  function handleRemoveAll() {
    setFiles([]);
  }

  const handleDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((element) => {
      var reader = new FileReader();
      reader.onload = (event) => {
        setFiles((files) =>
          [...files].concat(
            Object.assign(
              element,
              { base64: event.target.result },
              { preview: URL.createObjectURL(element) },
            ),
          ),
        );
      };
      reader.readAsDataURL(element);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: handleDrop,
    maxSize: props.MAX_SIZE,
  });

  const thumbs = files.map((file) => (
    <Root style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt={file.name || "Imagen sin descripción"}
        />
      </div>
    </Root>
  ));

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          {props.IMG ? (
            <img
              alt="Selecciona un archivo"
              className={classes.image}
              src={props.IMG}
            />
          ) : null}
        </div>
        <div>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            {props.MSG_PHOTOS1}{" "}
            <Link underline="always" color="#000000">
              ACÁ
            </Link>{" "}
            {props.MSG_PHOTOS2}
          </Typography>
        </div>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>

      {files.length > 0 && (
        <Fragment>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small">
              <DeleteForeverIcon className={classes.shareIcon} />
              {props.REM_PHOTOS}
            </Button>

            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={uploadPhotos}
            >
              <AddAPhotoIcon className={classes.shareIcon} />
              {props.ADD_PHOTOS}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

ProfilePhoto.propTypes = {
  className: PropTypes.string,
};

export default ProfilePhoto;
