import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";

import { Page } from "components";
import { Overview, BodyGraph, StandardBarGraph } from "./components";

const PREFIX = "DashboardAnalytics";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.container}`]: {
    "& > *": {},
  },
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  height: "100%", // Necessary for child components to use 100% height
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const DashboardAnalytics = () => {
  return (
    <StyledPage className={classes.root}>
      {/* <Header /> */}
      <Grid container className={classes.container} spacing={3}>
        <StyledGridItem item xs={12}>
          <Overview />
        </StyledGridItem>
        <StyledGridItem item lg={6} xs={12}>
          <StandardBarGraph
            metric="pain"
            title="Resumen del Dolor"
            dataLabel="Cantidad de pacientes por nivel de dolor"
          />
        </StyledGridItem>
        <StyledGridItem item lg={6} xs={12}>
          <StandardBarGraph
            metric="sleep"
            title="Resumen de horas de sueño"
            dataLabel="Cantidad de pacientes por horas de sueño"
          />
        </StyledGridItem>
        <StyledGridItem item lg={6} xs={12}>
          <StandardBarGraph
            metric="mood"
            title="Resumen del estado de ánimo"
            dataLabel="Cantidad de pacientes por estado de ánimo"
          />
        </StyledGridItem>
        <StyledGridItem item lg={6} xs={12}>
          <BodyGraph />
        </StyledGridItem>
      </Grid>
      <Box sx={{ paddingBottom: 3 }} /> {/* Add padding here */}
    </StyledPage>
  );
};

export default DashboardAnalytics;
