import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import StatisticsChart from "../../components/Todos/StatisticsChart"; // Import StatisticsChart
import { postStatsUser } from "utils/post";

const PREFIX = "Overview-CardStatistics";

const classes = {
  root: `${PREFIX}-root`,
  statisticsContent: `${PREFIX}-statisticsContent`,
  noDataText: `${PREFIX}-noDataText`, // New class added
};

const Root = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [`& .${classes.statisticsContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: theme.spacing(2),
    minHeight: "200px",
    paddingBottom: theme.spacing(2),
  },
  [`& .${classes.noDataText}`]: {
    textAlign: "left",
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: "16px",
    fontWeight: "500",
    fontStyle: "italic",
  },
}));

const CardStatistics = (props) => {
  const rootClasses = props.rootClasses;

  const accessToken = localStorage.getItem("currentToken");
  const patientId = localStorage.getItem("id");
  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
    async function fetchStats() {
      try {
        const accessToken = localStorage.getItem("currentToken");
        const answers = await postStatsUser(
          accessToken,
          patientId,
          "last-week",
        );
        
        const painData = answers.stats.pain.data;
        const sleepData = answers.stats.sleep.data;
        
        const filteredPainData = painData.dates
          .map((date, index) => ({
            date: date.split("T")[0].split("-").reverse().join("-"),
            Dolor: painData.values[index],
          }))
          .filter((record) => record.Dolor !== null);
        
        // Procesar datos de sueño
        const filteredSleepData = sleepData.dates
          .map((date, index) => ({
            date: date.split("T")[0].split("-").reverse().join("-"),
            Sueño: sleepData.values[index],
          }))
          .filter((record) => record.Sueño !== null);
        
        // Crear un conjunto de todos los días únicos
        const allDates = [...new Set([
          ...filteredPainData.map(d => d.date),
          ...filteredSleepData.map(d => d.date)
        ])];
        
        // Crear datos combinados usando todas las fechas
        const mergedData = allDates.map(date => {
          const painRecord = filteredPainData.find(p => p.date === date);
          const sleepRecord = filteredSleepData.find(s => s.date === date);
          
          return {
            name: date,
            Dolor: painRecord ? painRecord.Dolor : 0,
            Sueño: sleepRecord ? sleepRecord.Sueño : 0
          };
        });
        
        setStatsData(mergedData);
        
      } catch (error) {
        console.error("Error detallado:", {
          message: error.message,
          stack: error.stack,
          response: error.response
        });
      }
    }
    fetchStats();
  }, [accessToken, patientId]);

  return (
    <Root className={classes.root}>
      <Card className={rootClasses.card}>
        <CardHeader
          title={
            <Stack direction="column" spacing={1}>
              <Typography variant="h3">Estadísticas</Typography>
              <Typography variant="body1">
                Promedio de los últimos 7 días
              </Typography>
            </Stack>
          }
        />
        <Divider />
        <CardContent className={classes.statisticsContent}>
          {statsData.length > 0 ? (
            <>
              <StatisticsChart data={statsData} />
            </>
          ) : (
            <Typography
              className={classes.noDataText}
              variant="body1"
              style={{ textAlign: "left" }}
            >
              Debes responder el reporte diario para obtener estadísticas.
            </Typography>
          )}
        </CardContent>
      </Card>
    </Root>
  );
};

CardStatistics.propTypes = {
  rootClasses: PropTypes.object,
  userData: PropTypes.object,
  setIsSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default CardStatistics;
