import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getCuestionarios(accessToken, id) {
  const response = await fetch(`${URL}/users/${id}/reports/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
  });
  const res = await response.json();
  if (res.status === "error") {
    console.error("Error en getCuestionarios:", res.message);
    return { response, res: null };
  }
  if (checkResponse(response)) {
    const filteredRes = res.filter((report) => report.questionary !== null);
    const formattedRes = filteredRes.map((report) => {
      return {
        ...report,
        questionary_name: report.questionary.questionary_name,
        questions: report.questionary.questions,
      };
    });
    return { response, res: formattedRes };
  }
}
