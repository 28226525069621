import React, { Suspense, useState } from "react";
import { styled } from "@mui/material/styles";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import { NavBar, TopBar } from "./components";
import { ErrorBoundary } from "react-error-boundary";

const PREFIX = "Dashboard";

const classes = {
  root: `${PREFIX}-root`,
  preTopBar: `${PREFIX}-preTopBar`,
  topBar: `${PREFIX}-topBar`,
  container: `${PREFIX}-container`,
  navBar: `${PREFIX}-navBar`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    minHeight: "100%",
    width: "100%",
    maxWidth: "1600px",
    margin: "0 auto",
    display: "flex",
    paddingBottom: 24,
    flexDirection: "column",
    overflow: "hidden",
  },

  [`& .${classes.preTopBar}`]: {
    zIndex: 1099, // Asegura que el PreTopBar esté debajo del TopBar
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    width: "auto",
    margin: "0 auto",
    height: 48,
    background: "#f4f6f8",
  },

  [`& .${classes.topBar}`]: {
    zIndex: 1100, // Asegura que el TopBar esté encima de otros elementos
    position: "fixed", // Posiciona el TopBar de forma fija
    top: 24, // Fija el TopBar en la parte superior
    left: 24,
    right: 24,
    width: "auto",
    maxWidth: "1552px",
    margin: "0 auto",
    borderRadius: 24,
  },

  [`& .${classes.container}`]: {
    display: "flex",
    flex: "1 1 auto",
    marginTop: 100,
  },

  [`& .${classes.navBar}`]: {
    zIndex: 1000,
    width: 280,
    minWidth: 256,
    flex: "0 0 auto",
    marginTop: 24,
    marginLeft: 24,
    borderRadius: 24,
  },

  [`& .${classes.content}`]: {
    // overflowY: "auto",
    flex: "1 1 auto",
    marginLeft: 24,
    marginRight: 24,
    paddingTop: 24,
    height: "100%",
  },
}));

const Dashboard = (props) => {
  const { route } = props;
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <Root className={classes.root}>
      <div className={classes.preTopBar} />
      <TopBar
        route={route}
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <ErrorBoundary fallback={<div>Algo salió mal</div>}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
        </ErrorBoundary>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <ErrorBoundary fallback={<div>Algo salió mal</div>}>
              {renderRoutes(route.routes)}
            </ErrorBoundary>
          </Suspense>
        </main>
      </div>
    </Root>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
