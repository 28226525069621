import { URL } from "../url";
import { checkResponse } from "../checkResponse";

/**
 * Valida que los datos de la tarea cumplan con el formato requerido.
 * @param {Object} data
 */
function validateTaskData(data) {
  const requiredFields = [
    "name",
    "description",
    "tags",
    "priority",
    "specialtyId",
  ];
  for (const field of requiredFields) {
    if (!(field in data)) {
      throw new Error(`El campo '${field}' es obligatorio.`);
    }
  }
  if (typeof data.name !== "string" || typeof data.description !== "string") {
    throw new Error("Los campos 'name' y 'description' deben ser texto.");
  }
  if (!Array.isArray(data.tags)) {
    throw new Error("El campo 'tags' debe ser un arreglo.");
  }
  data.tags = data.tags.map((tag) => {
    const parsedTag = typeof tag === "number" ? tag : Number(tag);
    if (isNaN(parsedTag)) {
      throw new Error(
        `El valor '${tag}' en el campo 'tags' no es un número válido.`,
      );
    }
    return parsedTag;
  });
  if (
    typeof data.priority !== "number" ||
    typeof data.specialtyId !== "number"
  ) {
    throw new Error("Los campos 'priority' y 'specialtyId' deben ser números.");
  }
  if (data.file && !(data.file instanceof File)) {
    throw new Error("El campo 'file' debe ser un archivo.");
  }
}

/**
 * Crea una nueva tarea en el servidor.
 * @param {string} accessToken
 * @param {Object} data
 * @returns {Promise<Object>} Respuesta del servidor.
 */
export async function createNewTask(accessToken, data) {
  try {
    // Validar datos antes de enviarlos
    validateTaskData(data);
  } catch (error) {
    throw new Error(`Error al validar los datos de la tarea: ${error.message}`);
  }

  try {
    // Crear FormData para enviar archivos
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("priority", data.priority);
    formData.append("specialtyID", data.specialtyId);
    formData.append("tags", `[${data.tags.join(",")}]`);
    if (data.file) {
      formData.append("file", data.file);
    }

    // Realizar la petición
    const fetchResponse = await fetch(`${URL}/tasks/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${accessToken}`,
      },
      body: formData,
    });

    // Manejar errores de respuesta
    if (!fetchResponse.ok) {
      const errorData = await fetchResponse.json();
      console.error("Error en la respuesta del servidor:", errorData);
      throw new Error(
        `Error ${fetchResponse.status}: ${errorData.message || "Error desconocido"}`,
      );
    }

    // Validar respuesta con checkResponse y retornar datos
    const responseData = await fetchResponse.json();
    if (checkResponse(fetchResponse)) {
      return responseData;
    } else {
      throw new Error(
        "La respuesta no cumple con los criterios de validación.",
      );
    }
  } catch (error) {
    console.error("Error al crear la tarea (back):", error.message);
    throw error;
  }
}
