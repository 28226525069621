import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function updatePatientTask(ptID, data) {
  const accessToken = localStorage.getItem("currentToken");
  const response = await fetch(`${URL}/patientTasks/${ptID}`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return { response, res };
  }
}
