import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getAlertToday(senderId) {
  try {
    const accessToken = localStorage.getItem("currentToken");
    const response = await fetch(
      `${URL}/messages/checkPatientAlertToday/${senderId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${accessToken}`,
        },
      },
    );

    const data = await response.json();
    if (checkResponse(response)) {
      return data; // Will return { alert_exists: boolean, message: string }
    }
  } catch (error) {
    console.error("Failed to check today's alert:", error);
    throw error;
  }
}
