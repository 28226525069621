import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function patchExamFile(medicalExamID, file) {
  const formData = new FormData();
  formData.append("file", file); // Asignamos el archivo al campo `file`

  try {
    const accessToken = localStorage.getItem("currentToken"); // Obtener el token del localStorage

    const response = await fetch(`${URL}/medicalExams/${medicalExamID}/order`, {
      method: "PATCH",
      headers: {
        Authorization: `Token ${accessToken}`, // Autorización
      },
      body: formData, // Enviamos el FormData como body
    });

    // Verifica si la respuesta es válida antes de acceder a `.json()`
    if (!response.ok) {
      const errorDetails = await response.text(); // Obtiene el texto del error si no es JSON
      throw new Error(`Error del servidor: ${errorDetails}`);
    }

    const res = await response.json();

    // Verifica la respuesta usando `checkResponse`
    if (checkResponse(response)) {
      return res; // Retorna la respuesta si es exitosa
    } else {
      throw new Error(
        `Error al actualizar el archivo del examen: ${res.message || "Error desconocido"}`,
      );
    }
  } catch (error) {
    console.error("Error en patchExamFile:", error);
    return null; // Retorna null en caso de error
  }
}
