import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Modal,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { newAutoreporte } from "utils/post"; // Import newAutoreporte
import { Questionnaire } from "components/Questionnaire"; // Import Questionnaire
import { actualizarAutoreporte } from "utils/patch";
import { setNewBasicUserData } from "actions";
import { ReactComponent as SendIcon } from "../../../../assets/icons/send.svg";

const PREFIX = "Overview-CardWelcome";

const classes = {
  root: `${PREFIX}-root`,
};

const chooseChanges = (previousData) => {
  let outputData = previousData;
  outputData.answered_today = !previousData.answered_today;
  outputData.profile.stars = previousData.profile.stars + 1;
  return outputData;
};

const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  textTransform: "none",
}));

const Root = styled("div")(({ theme }) => ({}));

const CardWelcome = (props) => {
  const rootClasses = props.rootClasses;
  const userData = props.userData;
  const setIsSuccessModalOpen = props.setIsSuccessModalOpen;
  const setSuccessMessage = props.setSuccessMessage;
  const loading = props.loading;
  const setLoading = props.setLoading;

  const today = new Date().toLocaleDateString();
  const answeredToday = localStorage.getItem("answered_today") === "true";

  const theme = useTheme(); // Add this line to get the theme object
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [answers, setAnswers] = useState([]); // State for questionnaire answers
  const [report, setReport] = useState({
    questions: [],
    questionary_name: "Loading...",
  }); // State for questionnaire data
  const dispatch = useDispatch();

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    setLoading(true);
    try {
      const { res } = await newAutoreporte();
      res.questionary_name = "Autorreporte Diario";
      setReport(res);
    } catch (error) {
      console.error("Error fetching questionnaire:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateUserData = () => {
    var newUserData = chooseChanges(userData);
    dispatch(setNewBasicUserData(newUserData));
  };

  const handleSubmit = () => {
    actualizarAutoreporte(report.id, answers, true);
    localStorage.setItem("answered_today", "true");
    updateUserData();
    handleCloseModal();
    setSuccessMessage("Tu cuestionario ha sido enviado correctamente.");
    setIsSuccessModalOpen(true);
  };

  return (
    <Root className={classes.root}>
      <Card className={rootClasses.card}>
        <CardContent className={rootClasses.cardContent}>
          <Typography variant="h5" component="h2" className={rootClasses.title}>
            👋 Buenos días {userData.first_name}
          </Typography>
          <Typography color="textSecondary">
            {answeredToday
              ? `Ya has completado el autorreporte de hoy ${today}`
              : `Completa el autorreporte de hoy ${today}`}
          </Typography>
          {answeredToday ? (
            <Button
              className={rootClasses.successButton}
              variant="contained"
              component={RouterLink}
              to={`mi-diagnostico/informacion`}
            >
              Ir a Mi Diagnóstico
            </Button>
          ) : (
            <Button
              className={rootClasses.button}
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              startIcon={<AddCircleIcon />}
            >
              Completar Autorreporte
            </Button>
          )}
        </CardContent>
      </Card>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 1000, // Increased width
            margin: "auto",
            marginTop: "5%",
            padding: "24px",
            backgroundColor: theme.palette.background.default,
            borderRadius: 2,
            maxHeight: "80vh", // Set a maximum height
            overflowY: "auto", // Enable vertical scrolling
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {loading ? (
            <Typography variant="body1">Cargando...</Typography>
          ) : (
            <Questionnaire questionary={report} updateAnswers={setAnswers} />
          )}
          <div className={classes.actions} style={{ marginTop: "24px" }}>
            <Box display="flex" justifyContent="center" mt={2}>
              <CustomButton
                className={classes.sendButton}
                color="secondary"
                variant="contained"
                onClick={handleCloseModal}
                style={{ marginRight: "8px" }}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                className={classes.sendButton}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                startIcon={<SendIcon />} // Include the SendIcon here
              >
                Enviar cuestionario
              </CustomButton>
            </Box>
          </div>
        </Box>
      </Modal>
    </Root>
  );
};

CardWelcome.propTypes = {
  rootClasses: PropTypes.object,
  userData: PropTypes.object,
  setIsSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default CardWelcome;
