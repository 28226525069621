import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getNotifications(accessToken, userID) {
  try {
    const response = await fetch(`${URL}/users/${userID}/notifications`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();
    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return null;
  }
}
