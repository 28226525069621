import { URL } from "../url";

export async function resetPassword(data) {
  try {
    const response = await fetch(`${URL}/users/reset-password/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: data.code,
        new_password: data.password,
        confirm_new_password: data.password2,
      }),
    });
    await response.json();
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}
