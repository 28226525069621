import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function newAutoreporte() {
  const accessToken = localStorage.getItem("currentToken");
  const id = localStorage.getItem("id").toString();
  try {
    const response = await fetch(`${URL}/logs`, {
      method: "POST",
      headers: {
        Authorization: `Token ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    try {
      checkResponse(response);
    } catch (error) {
      // if error 400, do not raise error
      if (response.status === 400) {
        const res = (await response.json()) || {};
        return { response, res };
      }
      throw error;
    }
    const res = (await response.json()) || {};
    return { response, res };
  } catch (error) {
    console.error("Error al obtener autoreporte:", error);
    throw error;
  }
}
