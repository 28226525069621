import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function readMessage(id, accessToken) {
  const response = await fetch(`${URL}/messages/${id}/read/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
      messageID: id,
    },
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
