import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getAvailableProfessionals(accessToken, patient_id) {
  try {
    const response = await fetch(
      `${URL}/users/${patient_id}/available_professionals`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${accessToken}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      console.error(`Error en el servidor: ${response.status}`);
      return null;
    }

    const res = await response.json();

    if (checkResponse(response)) {
      return res.attendants;
    } else {
      console.warn("Respuesta del servidor no válida:", res);
      return null;
    }
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    alert("Error al realizar la solicitud. Por favor, inténtalo nuevamente.");
    return null;
  }
}
