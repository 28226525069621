import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getAllDoctors } from "utils/fetch/getAllDoctors"; // Asegúrate de que la ruta de importación sea correcta
import { Grid, Box, Modal } from "@mui/material";
import { colors } from "@mui/material";
import { getAttendantsUser } from "utils/fetch/getAttendantsUser"; // Import the function
import SuccessMessage from "./components/SuccessMessage"; // Import SuccessModal
import {
  CardCrisis,
  CardNotifications,
  CardStatistics,
  CardTasks,
  CardTratantes,
  CardWelcome,
} from "./components/Cards";

const PREFIX = "Overview";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  button: `${PREFIX}-button`,
  successButton: `${PREFIX}-successButton`,
  title: `${PREFIX}-title`,
  checked: `${PREFIX}-checked`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  content: `${PREFIX}-content`,
  opcion_espeficica: `${PREFIX}-opcion_espeficica`,
  addIcon: `${PREFIX}-addIcon`,
  eliminar_opcion: `${PREFIX}-eliminar_opcion`,
  contenedor_martin: `${PREFIX}-contenedor_martin`,
  textoNuevaOpcion: `${PREFIX}-textoNuevaOpcion`,
  optionRadio: `${PREFIX}-optionRadio`,
  done: `${PREFIX}-done`,
  buttonStyle: `${PREFIX}-buttonStyle`,
  sendButton: `${PREFIX}-sendButton`, // New class added
  actions: `${PREFIX}-actions`, // New class added
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    // padding: theme.spacing(3),
  },
  [`& .${classes.card}`]: {
    marginBottom: theme.spacing(3),
    borderRadius: "24px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  [`& .${classes.cardContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(3),
  },
  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
    borderRadius: "24px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.successButton}`]: {
    marginTop: theme.spacing(2),
    borderRadius: "24px",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.title}`]: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.root3}`]: {},
  [`& .${classes.content}`]: {
    padding: 0,
  },
  [`& .${classes.opcion_espeficica}`]: {
    marginLeft: theme.spacing(2.7),
    marginTop: theme.spacing(0.5),
  },
  [`& .${classes.addIcon}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.eliminar_opcion}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.contenedor_martin}`]: {
    display: "inline-flex",
    width: theme.spacing(100),
    marginLeft: theme.spacing(1),
  },
  [`& .${classes.textoNuevaOpcion}`]: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  [`& .${classes.optionRadio}`]: {
    marginTop: theme.spacing(1.3),
    marginLeft: theme.spacing(0.5),
  },
  [`& .${classes.done}`]: {
    color: "#bdbdbd",
  },
  [`& .${classes.buttonStyle}`]: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    "&:hover": {
      backgroundColor: colors.blue[900],
    },
  },
  [`& .${classes.sendButton}`]: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    borderRadius: "24px",
    backgroundColor: "#802e87",
    "&:hover": {
      backgroundColor: colors.blue[900],
    },
  },
  [`& .${classes.actions}`]: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

const Overview = (props) => {
  const userData = useSelector((state) => state.userData.serverUserData);
  const [loading, setLoading] = useState(true); // State for loading
  const [attendants, setAttendants] = useState([]); // State to store the list of doctors
  const [availableProfessionals, setAvailableProfessionals] = useState([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // State for success modal visibility
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  useEffect(() => {
    async function fetchAttendants() {
      const accessToken = localStorage.getItem("currentToken");
      const patientId = localStorage.getItem("id");

      try {
        const fetchedAttendants = await getAttendantsUser(
          accessToken,
          patientId,
        );

        setAttendants(fetchedAttendants); // Set the fetched attendants to state
      } catch (error) {
        console.error("Error fetching attendants:", error);
      }
    }

    fetchAttendants();
  }, []); // Empty dependency array to run once on component mount

  useEffect(() => {
    async function fetchProfessionals() {
      const accessToken = localStorage.getItem("currentToken");
      const userID = localStorage.getItem("id");
      const res = await getAllDoctors(accessToken, userID);
      if (Array.isArray(res.doctors)) {
        setAvailableProfessionals(res.doctors);
      } else {
        console.error("Expected an array but got:", res);
        setAvailableProfessionals([]); // Fallback to an empty array
      }
    }

    fetchProfessionals();
  }, []); // Arreglo de dependencias vacío para ejecutar una vez al montar el componente

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    window.location.reload();
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CardWelcome
            rootClasses={classes}
            userData={userData}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            setSuccessMessage={setSuccessMessage}
            loading={loading}
            setLoading={setLoading}
          />

          {/* New Crisis Card */}
          <CardCrisis
            rootClasses={classes}
            userData={userData}
            loading={loading}
            setLoading={setLoading}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            setSuccessMessage={setSuccessMessage}
            attendants={attendants}
          />

          {/* Notificaciones Card */}
          <CardNotifications
            rootClasses={classes}
            userData={userData}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            setSuccessMessage={setSuccessMessage}
            loading={loading}
            setLoading={setLoading}
          />

          {/* Médicos Tratantes Card */}
          <CardTratantes
            rootClasses={classes}
            userData={userData}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            setSuccessMessage={setSuccessMessage}
            loading={loading}
            setLoading={setLoading}
            attendants={attendants}
            availableProfessionals={availableProfessionals}
          />
        </Grid>

        {/* Combine Tareas Asignadas and Estadísticas into one Grid item */}
        <Grid item xs={12} md={8}>
          {/* Tareas Asignadas Card */}
          <CardTasks
            rootClasses={classes}
            userData={userData}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            setSuccessMessage={setSuccessMessage}
            loading={loading}
            setLoading={setLoading}
          />

          {/* Estadísticas Card */}
          <CardStatistics
            rootClasses={classes}
            userData={userData}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            setSuccessMessage={setSuccessMessage}
            loading={loading}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>

      {/* Success Modal */}
      <Modal open={isSuccessModalOpen} onClose={handleCloseSuccessModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <SuccessMessage
            title="Éxito"
            message={successMessage}
            onClose={handleCloseSuccessModal}
          />
        </Box>
      </Modal>
    </Root>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
