import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getPatientMedicines(accessToken, patientId) {
  try {
    const response = await fetch(`${URL}/medicines/patient/${patientId}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (checkResponse(response)) {
      const data = await response.json();
      return data;
    } else {
      console.error(
        `Failed request: ${response.status} ${response.statusText}`,
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching patient medicines:", error);
    return null;
  }
}
