import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function actualizarPartsPaciente(listado, accessToken, id) {
  const output = { areas: listado };
  const response = await fetch(`${URL}/users/${id}/body-map/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      areas: listado,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return { response, res };
  }
}
