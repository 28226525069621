import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editTask(
  id,
  name,
  description,
  priority,
  tags,
  accessToken,
) {
  const response = await fetch(`${URL}/tasks/${id}/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      name: name,
      description: description,
      priority: priority,
      tags: tags,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return res;
  }
}
