import { URL } from "../url";

export async function validateAccount(token) {
  const output = { token: token };
  const response = await fetch(`${URL}/users/validate-account/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(output),
  });
  const res = await response.json();

  return { response, res };
}
