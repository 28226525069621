let MuiButton = {
  styleOverrides: {
    root: {
      textTransform: "none", // Deshabilita el uppercase en todos los botones
      borderRadius: 24,
    },
    // containedPrimary: {
    //   // "&:hover": {
    //   //   backgroundColor: "#365f92", // Color de hover para botones primarios
    //   // },
    // },
    // containedSecondary: {
    //   color: "#4476B5", // Texto en color azul
    //   // backgroundColor: "#FFFFFF", // Fondo blanco
    //   // "&:hover": {
    //   //   backgroundColor: "#E3F2FD", // Mantiene el fondo blanco al hacer hover
    //   //   boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)", // Sombra al hacer hover
    //   // },
    // },
  },
};

export default MuiButton;
