import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function changeGroupName(roomId, name) {
  const accessToken = localStorage.getItem("currentToken");
  const id = localStorage.getItem("id").toString();
  const response = await fetch(`${URL}/chats/changeGroupName/${id}/`, {
    method: "PUT",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      roomId: roomId,
      name: name,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return { response, res };
  }
}
