import React, { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Bar } from "react-chartjs-2";
import {
  Typography,
  Card,
  CardHeader,
  IconButton,
  Grid,
  Menu,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CloseIcon from "@mui/icons-material/Close";
import palette from "../../../theme/palette";
import { postSummary, postOverview } from "utils/post";

const PREFIX = "StandardBarGraph";

const classes = {
  root: `${PREFIX}-root`,
  headerRoot: `${PREFIX}-headerRoot`,
  headerTitle: `${PREFIX}-headerTitle`,
  menuButton: `${PREFIX}-menuButton`,
};

const colorMap = {
  sleep: {
    background: palette.graph.sleep.light,
    border: palette.graph.sleep.dark,
  },
  pain: {
    background: palette.graph.pain.light,
    border: palette.graph.pain.dark,
  },
  mood: {
    background: palette.graph.mood.light,
    border: palette.graph.mood.dark,
  },
  default: { background: palette.error.light, border: palette.error.dark },
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "24px",
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.background.paper,
    minWidth: "250px",
  },
  "& .MuiMenu-list": {
    padding: theme.spacing(1),
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  ".MuiTypography-root": {
    fontWeight: "600",
    fontSize: 14,
    color: theme.palette.text.primary,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(0),
    borderRadius: "24px",
    backgroundColor: theme.palette.white,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  [`& .${classes.headerRoot}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  [`& .${classes.headerTitle}`]: {
    fontSize: 16,
    fontWeight: "600",
    color: palette.text.primary,
  },
  [`& .${classes.menuButton}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const StandardBarGraph = ({ metric, title, dataLabel }) => {
  const [time, setTime] = useState("all");
  const [graphData, setGraphData] = useState({
    labels: [],
    values: [],
    max: 10,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const accessToken = localStorage.getItem("currentToken");
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const fetchData = useCallback(
    async (timeValue) => {
      let answers;
      if (role === "admin") {
        answers = await postOverview(accessToken, timeValue);
      } else {
        answers = await postSummary(accessToken, id, timeValue);
      }

      const valuesAnswers = answers["stats"][metric]["data"];
      const max = answers["stats"][metric]["answers"];
      const labels = Object.keys(valuesAnswers).reverse();
      const values = Object.values(valuesAnswers).reverse();

      setGraphData({ labels, values, max });
    },
    [metric, role, accessToken, id],
  );

  useEffect(() => {
    fetchData(time);
  }, [time, fetchData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsActive((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleMenuItemClick = (value) => {
    setTime(value);
    // handleClose();
  };

  const { background, border } = colorMap[metric] || colorMap.default;

  const data = {
    labels: graphData.labels,
    datasets: [
      {
        label: dataLabel,
        data: graphData.values,
        backgroundColor: background,
        borderColor: border,
        borderWidth: 2,
        borderRadius: 6,
        fill: false,
        datalabels: {
          display: false,
          color: "black",
          anchor: "end",
          align: "end",
          formatter: (value) => {
            return Math.round(value); // Valores como enteros
          },
        },
      },
    ],
  };

  const options = {
    indexAxis: "x",
    scales: {
      x: {
        beginAtZero: true,
        reverse: true,
        min: 0,
        max: graphData.max,
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        ticks: { stepSize: 1 },
        grid: { display: false },
      },
    },
    plugins: { legend: { display: false } },
    layout: { padding: { left: 10, bottom: 10 } },
  };

  const heightVh = 50;
  const aspectRatio = 12 / 7;
  const widthVw = heightVh * aspectRatio;

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        title={title}
        classes={{ title: classes.headerTitle, root: classes.headerRoot }}
        action={
          <IconButton
            className={`${classes.menuButton} ${isActive ? "active" : ""}`}
            onClick={handleClick}
          >
            <TuneRoundedIcon style={{ fontSize: 16 }} />
          </IconButton>
        }
      />
      <Grid container justifyContent="flex-end">
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="overline" sx={{ marginLeft: 2 }}>
              Filtros
            </Typography>
            <IconButton
              size="small"
              onClick={handleClose}
              sx={{ marginRight: 1 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <StyledRadioGroup
            value={time}
            onChange={(event) => handleMenuItemClick(event.target.value)}
            style={{ padding: 0 }}
          >
            <StyledFormControlLabel
              value="last-week"
              control={<Radio />}
              label="Última semana"
            />
            <StyledFormControlLabel
              value="last-month"
              control={<Radio />}
              label="Último mes"
            />
            <StyledFormControlLabel
              value="last3"
              control={<Radio />}
              label="Últimos 3 meses"
            />
            <StyledFormControlLabel
              value="all"
              control={<Radio />}
              label="Todo el tiempo"
            />
          </StyledRadioGroup>
        </StyledMenu>
      </Grid>
      <Bar
        data={data}
        options={options}
        height={`${heightVh}vh`}
        width={`${widthVw}vw`}
        style={{ backgroundColor: palette.background.secondary }}
      />
    </StyledCard>
  );
};

export default StandardBarGraph;
