import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getCurrentUser(accessToken, id) {
  try {
    const response = await fetch(`${URL}/users/${id}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Validar la respuesta antes de procesar
    checkResponse(response);

    const user = await response.json();

    // Retornar datos organizados y validados
    return {
      id: user.id || null,
      role: user.Role || null,
      rut: user.rut || "",
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      dob: user.dob || null,
      email: user.email || "",
      avatar: user.image || null,
      nick: user.nick || "",
      address: user.address || "",
      cellphone_num: user.cellphone_num || "",
      diagnosis: user.diagnosis || [],
      specialists: user.specialists || [],
      tags: user.tags || [],
      base_pain: user.base_pain || null,
      periodicity: user.periodicity || "",
      answered_today: user.answered_today || false,
      is_active: user.is_active || false,
      messaging: user.messaging || null,
      profile: {
        specialty: user.specialty_name || "",
        unread_messages: 0,
        unread_alerts: 0,
        stars: user.stars || 0,
        stars_date: user.stars_date || null,
      },
      body_map: user.body_map || null,
    };
  } catch (error) {
    console.error("Error en getCurrentUser:", error);
    return null;
  }
}
