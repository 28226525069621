import { URL } from "../url";
import { checkResponse } from "../checkResponse";
export async function deleteArticle(id, accessToken) {
  const response = await fetch(`${URL}/articles/${id}/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
  });
  const res = await response;
  if (checkResponse(res)) {
    return res;
  }
}
