import React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  Radio,
  TextField,
  MenuItem,
  FormControl,
  Slider,
  Select,
  CardActions,
  Button,
  Grid,
  IconButton,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const PREFIX = "NewPreguntaCard";

const classes = {
  item: `${PREFIX}-item`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.item}`]: {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputLabel-root": {
    color: "black",
    "&.Mui-focused": {
      color: "black",
    },
    "&.Mui-disabled": {
      color: "#A1A1A1",
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: "24px",
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#A1A1A1",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      "& input, & textarea": {
        color: "#A1A1A1",
      },
    },
    "& input": {
      color: "black",
    },
    "& textarea": {
      color: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "16px",
    },
  },
  "& .MuiSelect-select": {
    color: "black",
  },
  "& .MuiMenuItem-root": {
    color: "black",
  },
  "& .MuiSelect-icon": {
    color: "#A1A1A1",
  },
  "& .MuiIconButton-root": {
    color: "#A1A1A1",
  },
  "& .MuiButton-root": {
    color: "#A1A1A1",
    borderColor: "#A1A1A1",
    "&:hover": {
      borderColor: "#A1A1A1",
    },
  },
  "& .MuiSlider-root": {
    color: "#A1A1A1",
  },
  "& .MuiRadio-root": {
    color: "#A1A1A1",
  },
}));

const NewQuestionCard = (props) => {
  const { question, questionIdx, updateQuestion, deleteQuestion } = props;

  const type = question.value || "text";
  const options = question.options || [];

  function handleTypeChange(event) {
    const newType = event.target.value;
    updateQuestion(questionIdx, { ...question, value: newType });
  }

  function handleTextChange(event) {
    const newText = event.target.value;
    updateQuestion(questionIdx, { ...question, text: newText });
  }

  function handleAddOption() {
    const newOptions = [...options, ""];
    updateQuestion(questionIdx, { ...question, options: newOptions });
  }

  function handleDeleteOption(optionIdx) {
    const newOptions = [...options];
    newOptions.splice(optionIdx, 1);
    updateQuestion(questionIdx, { ...question, options: newOptions });
  }

  function handleOptionChange(newValue, optionIdx) {
    const newOptions = [...options];
    newOptions[optionIdx] = newValue;
    updateQuestion(questionIdx, { ...question, options: newOptions });
  }

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="typeSelectLabel">Tipo de Pregunta</InputLabel>
              <Select
                labelId="typeSelectLabel"
                label="Tipo de Pregunta"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value="scale">Escala</MenuItem>
                <MenuItem value="text">Texto Abierto</MenuItem>
                <MenuItem value="options">Opciones</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth>
              <TextField
                label="Defina la pregunta"
                value={question.text}
                onChange={handleTextChange}
              />
            </FormControl>
          </Grid>
        </Grid>

        {type === "scale" && (
          <FormControl className={classes.item} fullWidth>
            <Slider
              color="secondary"
              min={0}
              max={10}
              value={5}
              marks
              disabled
              getAriaValueText={(value) => `${value}`}
              valueLabelDisplay="off"
            />
          </FormControl>
        )}
        {type === "text" && (
          <FormControl className={classes.item} fullWidth>
            <TextField label="Respuesta" multiline rows={4} disabled />
          </FormControl>
        )}
        {type === "options" && (
          <div className={classes.item}>
            <Typography variant="h6">Opciones</Typography>
            {options.map((option, index) => (
              <Grid container spacing={1} key={index} className={classes.item}>
                <Grid item direction="row">
                  <Radio disabled />
                </Grid>

                <Grid item xs>
                  <TextField
                    fullWidth
                    name={index}
                    value={option}
                    onChange={(e) => handleOptionChange(e.target.value, index)}
                    size="small"
                  />
                </Grid>

                <Grid item>
                  <IconButton
                    color="secondary"
                    variant="outlined"
                    onClick={() => handleDeleteOption(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={handleAddOption}
              className={classes.item}
              startIcon={<AddIcon />}
            >
              Añadir Opción
            </Button>
          </div>
        )}
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "flex-end",
          padding: "0 16px 16px 16px",
        }}
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => deleteQuestion(questionIdx)}
        >
          Eliminar Pregunta
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default NewQuestionCard;
