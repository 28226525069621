import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function changeUserImage(file, user_id, accessToken) {
  const formData = new FormData();
  formData.append("file", file); // Asignamos el archivo al campo `file`

  const response = await fetch(`${URL}/users/${user_id}/image`, {
    method: "POST",
    headers: {
      Authorization: `Token ${accessToken}`,
    },
    body: formData, // Enviamos el FormData como body
  });

  const res = await response.json();
  if (checkResponse(response)) {
    console.log("Imagen de perfil cambiada con éxito");
    console.log(res);
    return res;
  } else {
    throw new Error("Error al cambiar la imagen de perfil");
  }
}
