import { getAlerts } from "./getAlerts";

export async function getCrisisCount(accessToken) {
  try {
    const currentUserId = parseInt(localStorage.getItem("id"));
    const alerts = await getAlerts(accessToken, currentUserId);

    if (alerts) {
      const unreadCrisisCount = alerts.filter(
        (message) =>
          message.alert === true &&
          message.receiverID === currentUserId &&
          message.read === false &&
          message.senderID !== null,
      ).length;

      return unreadCrisisCount;
    }
    return 0;
  } catch (error) {
    console.error("Error in getCrisisCount:", error);
    return 0;
  }
}
