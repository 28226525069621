import { URL } from "../url";

export async function postAlert(senderId, receiverId, content) {
  try {
    const accessToken = localStorage.getItem("currentToken");
    const response = await fetch(`${URL}/messages`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
      body: JSON.stringify({
        receiver: receiverId,
        content,
        sender: senderId,
        subject: "Alerta por crisis",
        alert: true,
        patient_alert: true,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return data;
  } catch (error) {
    console.error("Failed to create alert:", error);
    throw error;
  }
}
