import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Slider,
  Typography,
} from "@mui/material";

const PREFIX = "QEscale";

const classes = {
  root: `${PREFIX}-root`,
  slider: `${PREFIX}-slider`,
  SpaceBar: `${PREFIX}-SpaceBar`,
};

const StyledCard = styled(Card)(({ theme, isError }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    border: isError ? "2px solid red" : `1px solid ${theme.palette.divider}`, // Borde condicional
    borderRadius: theme.spacing(1),
  },

  [`& .${classes.slider}`]: {
    maxWidth: 600,
  },

  [`& .${classes.SpaceBar}`]: {
    maxWidth: 600,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

// Custom styled Slider
const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#4476B5", // Azul como en StyledCard

  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#4476B5", // Azul intenso para el thumb
    border: `2px solid #4476B5`,
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(0, 0, 0, 0.16)",
    },
  },

  "& .MuiSlider-track": {
    height: 8, // Grosor del track
    background: "linear-gradient(90deg, #bbdefb, #4476B5)", // Gradiente como en StyledCard
    border: "none", // Sin borde en el track
  },

  "& .MuiSlider-rail": {
    height: 8, // Grosor del rail
    backgroundColor: "#E0E0E0", // Gris claro para el rail
    border: "none", // Sin borde en el rail
  },

  "& .MuiSlider-markLabel": {
    fontSize: "12px", // Ajuste de tamaño de la etiqueta
  },

  "& .MuiSlider-mark": {
    display: "none", // Esconder los puntos (marcas)
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const QScale = (props) => {
  const { question, answer, questionNumber, answerQuestion, isError, ...rest } =
    props;
  const { text } = question;

  const [selectedValue, setSelectedValue] = useState(answer ?? 5);

  const handleSliderChange = (event, newValue) => {
    setSelectedValue(newValue);
    answerQuestion(questionNumber, newValue);
  };

  const marks = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  return (
    <StyledCard {...rest} className={classes.root} isError={isError}>
      <CardHeader
        title={<StyledTypography variant="h6">{text}</StyledTypography>}
        style={{ padding: 0, marginBottom: "12px" }}
      />

      <CardContent>
        <div className={classes.slider} style={{ position: "relative" }}>
          <StyledTypography
            id="discrete-slider"
            gutterBottom
          ></StyledTypography>
          <CustomSlider
            {...rest}
            className={classes.SpaceBar}
            color="secondary"
            onChange={handleSliderChange}
            value={selectedValue}
            getAriaValueText={(value) => `${value}`}
            valueLabelDisplay="off"
            marks={marks}
            min={1}
            max={10}
            step={1}
          />
        </div>
      </CardContent>
    </StyledCard>
  );
};

export default QScale;
