import { URL } from "../url";

export async function getTagsUser(accessToken, user_id) {
  try {
    const response = await fetch(`${URL}/users/${user_id}/tags`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();
    return res || [];
  } catch (error) {
    return null;
  }
}
