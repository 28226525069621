/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Box,
  CircularProgress,
  colors,
  Typography,
} from "@mui/material";
import InputIcon from "@mui/icons-material/Input";
import MenuIcon from "@mui/icons-material/Menu";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import AddIcon from "@mui/icons-material/Add";
import StarIcon from "@mui/icons-material/Star";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { PricingModal } from "components";
import { logOut } from "actions";
import { useHistory } from "react-router";
import theme from "theme";

const PREFIX = "TopBar";

const classes = {
  root: `${PREFIX}-root`,
  flexGrow: `${PREFIX}-flexGrow`,
  fabProgress: `${PREFIX}-fabProgress`,
  loader: `${PREFIX}-loader`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  searchInput: `${PREFIX}-searchInput`,
  searchPopper: `${PREFIX}-searchPopper`,
  searchPopperContent: `${PREFIX}-searchPopperContent`,
  trialButton: `${PREFIX}-trialButton`,
  trialIcon: `${PREFIX}-trialIcon`,
  notificationsButton: `${PREFIX}-notificationsButton`,
  notificationsBadge: `${PREFIX}-notificationsBadge`,
  logoutButton: `${PREFIX}-logoutButton`,
  signOutButton: `${PREFIX}-signOutButton`,
  logoutIcon: `${PREFIX}-logoutIcon`,
  mainIcon: `${PREFIX}-mainIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    // boxShadow: true,
    backgroundColor: theme.palette.white,
  },
  [`& .${classes.flexGrow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.trialButton}`]: {
    marginLeft: theme.spacing(2),
  },
  // [`& .${classes.trialButton}`]: {
  //   marginLeft: theme.spacing(2),
  //   color: theme.palette.white,
  //   backgroundColor: "#802e87",
  //   "&:hover": {
  //     backgroundColor: colors.blue[900],
  //   },
  // },
  [`& .${classes.trialIcon}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.logoutButton}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.logoutIcon}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.mainIcon}`]: {
    height: 40,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, route, className, ...rest } = props;

  const {
    aparecen,
    cargandon,
    successn,
    setAparecen,
    setCargandon,
    setSuccessn,
  } = route;

  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const role = localStorage.getItem("role");

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };
  const handleButtonClick = () => {
    if (successn) {
      setSuccessn(false);
      setAparecen(false);
      setCargandon(true);
    }
  };
  const handleWebPage = () => {
    window.open("https://agenda.ucchristus.cl/");
  };

  const buttonConfig = {
    patient: [
      // {
      //   to: "/autorreporte",
      //   icon: <ListAltIcon className={classes.trialIcon} />,
      //   text: "Autorreporte",
      //   variant: "outlined",
      //   classes: [classes.trialButton],
      // },
      // {
      //   onClick: handleWebPage,
      //   icon: <EventIcon className={classes.trialIcon} />,
      //   text: "Reserva Médica",
      //   variant: "contained",
      //   classes: [classes.trialButton],
      // },
    ],
    professional: [
      {
        to: "/new-paciente",
        icon: <AddIcon className={classes.trialIcon} />,
        text: "Nuevo Usuario",
        variant: "outlined",
        classes: [classes.trialButton],
      },
      {
        to: "/pacientes",
        icon: <PeopleIcon className={classes.trialIcon} />,
        text: "Mis Pacientes",
        variant: "contained",
        classes: [classes.trialButton],
      },
    ],
  };

  const buttons = buttonConfig[role] || [];

  const userData = useSelector((state) => state.userData.serverUserData);

  let stars = 0;
  if (userData.profile) {
    stars = Math.min(userData.profile.stars || 0, 2);
  }

  return (
    <Root>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        color="primary"
      >
        <Toolbar>
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <IconButton
              name="navbar"
              color="primary"
              onClick={onOpenNavBarMobile}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <RouterLink to="/">
            <img
              className={classes.mainIcon}
              alt="Logo"
              src="/images/logos/logo--red.png"
            />
          </RouterLink>
          <div className={classes.flexGrow} />

          {/* Mostrar botones según rol */}
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {buttons.map((button, index) => (
              <Button
                key={index}
                className={clsx(button.classes)}
                component={button.to ? RouterLink : "button"}
                variant={button.variant}
                to={button.to}
                onClick={button.onClick}
              >
                {button.icon}
                {button.text}
              </Button>
            ))}
            {role === "patient" && (
              <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                <StarIcon
                  sx={{ color: theme.palette.primary.main, marginRight: "4px" }}
                />{" "}
                {/* Color dorado para la estrella */}
                <Typography variant="h5" marginTop="0.35rem">
                  Tienes
                  <Typography
                    variant="h4"
                    component="span"
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    {" " + stars + " "}
                  </Typography>
                  {stars === 1 ? "punto" : "puntos"}
                </Typography>
              </Box>
            )}
          </Box>
        </Toolbar>
        {/* <PricingModal onClose={handlePricingClose} open={pricingModalOpen} /> */}
      </AppBar>
    </Root>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
