const MuiInputBase = {
  root: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          borderRadius: "24px !important",
        },
      },
    ],
  },
};

export default MuiInputBase;
