import { URL } from "../url";

export async function authenticate(username, password) {
  const response = await fetch(`${URL}/authenticate/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${username}:${password}`),
    },
  });
  const res = await response.json();

  return { response, res };
}
