import React from 'react';
import { useSelector } from 'react-redux';
import SidebarChatbot from './Chatbot';

const ChatbotLauncher = () => {
  const isLoggedIn = useSelector((state) => state.session.loggingIn);
  const role = localStorage.getItem('role');
  const shouldShowChatbot = isLoggedIn && role === 'patient';

  if (!shouldShowChatbot) {
    return null;
  }

  return <SidebarChatbot />;
};

export default ChatbotLauncher;
