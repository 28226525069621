import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { getAllSpecialties } from "utils/fetch"; // Asume que tienes una función para obtener las especialidades

const PREFIX = "SpecialtyInput";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  selectField: `${PREFIX}-selectField`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    backgroundColor: theme.palette.background.white,
    borderRadius: "24px",
    marginBottom: theme.spacing(3),
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  [`&.${classes.cardContent}`]: {
    padding: "16px 16px 16px",
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.selectField}`]: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px", // Borde redondeado
      backgroundColor: theme.palette.background.default, // Fondo uniforme
      "& fieldset": {
        borderRadius: "16px", // Asegura que el borde del fieldset también sea redondeado
      },
    },
    "& .MuiSelect-select": {
      borderRadius: "16px", // Asegura que el área seleccionable sea redondeada
      backgroundColor: "inherit", // Hereda el fondo
      color: "black",
    },
    "& .MuiMenuItem-root": {
      color: "black", // Cambia el color de las opciones a negro
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },
  },
}));

const SpecialtyInput = ({ onSpecialtyChange, initialSpecialty }) => {
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    initialSpecialty || "",
  );

  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const { res } = await getAllSpecialties();
        const sortedSpecialties = res.sort((a, b) =>
          a.specialty_name.localeCompare(b.specialty_name),
        );
        setSpecialties(sortedSpecialties);
      } catch (error) {
        console.error("Error fetching specialties:", error);
      }
    };

    fetchSpecialties();
  }, []);

  useEffect(() => {
    setSelectedSpecialty(initialSpecialty || "");
  }, [initialSpecialty]);

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedSpecialty(selectedId);
    onSpecialtyChange(selectedId);
  };

  return (
    <StyledCard className={classes.card}>
      <StyledCardContent className={classes.cardContent}>
        <StyledFormControl className={classes.selectField}>
          <InputLabel id="specialty-select-label">Especialidad</InputLabel>
          <Select
            labelId="specialty-select-label"
            id="specialty-select"
            value={selectedSpecialty}
            label="Especialidad"
            onChange={handleChange}
          >
            {specialties.map((specialty) => (
              <MenuItem key={specialty.id} value={specialty.id}>
                {specialty.specialty_name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </StyledCardContent>
    </StyledCard>
  );
};

export default SpecialtyInput;
