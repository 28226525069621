import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { postAlert } from "../../../utils/post/postAlerts";

const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.error.dark, // Using MUI's darker error shade
  // Alternative: color: '#d32f2f', // Custom darker red
  textAlign: "center",
  fontWeight: 600,
  fontSize: "2rem",
  padding: theme.spacing(3),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: "#D828211A", // Custom light red background
  color: theme.palette.error.dark, // Same dark red as the title
  "& .MuiAlert-icon": {
    color: theme.palette.error.dark, // Icon color matching the text
  },
  padding: theme.spacing(2),
  fontSize: "0.95rem",
  fontWeight: 500,
  "& .MuiAlert-message": {
    padding: "8px 0",
  },
}));

const AlertModal = ({ open, onClose, onSuccess, attendants }) => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [crisisDescription, setCrisisDescription] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      const senderId = localStorage.getItem("id");
      const response = await postAlert(
        senderId,
        selectedDoctor,
        crisisDescription,
      );

      if (response.alert_exists) {
        setError(response.message);
        return;
      }

      setSelectedDoctor("");
      setCrisisDescription("");
      setError("");

      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error sending alert:", error);
      setError(
        "Ha ocurrido un error al enviar la alerta. Por favor, intente nuevamente.",
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      <StyledTitle>Reportar Crisis</StyledTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 0 }}>
          <StyledAlert severity="warning">
            Las crisis solo pueden ser reportadas una vez al día. Esta medida
            garantiza una mejor atención y manejo de los pacientes.
          </StyledAlert>

          {error && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {error}
            </Alert>
          )}

          <FormControl fullWidth>
            <InputLabel>Seleccionar Médico</InputLabel>
            <Select
              value={selectedDoctor}
              onChange={(e) => setSelectedDoctor(e.target.value)}
              label="Seleccionar Médico"
              sx={{ backgroundColor: "background.default" }}
            >
              {attendants.map((doctor) => (
                <MenuItem key={doctor.id} value={doctor.id}>
                  {doctor.first_name} {doctor.last_name} -{" "}
                  {doctor.specialty_name || "Especialidad no especificada"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Descripción de la Crisis"
            value={crisisDescription}
            onChange={(e) => setCrisisDescription(e.target.value)}
            placeholder="Por favor, describe tu situación actual..."
            sx={{ backgroundColor: "background.default" }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="error"
          disabled={!selectedDoctor || !crisisDescription}
        >
          Enviar Alerta
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
