import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import SuccessIcon from "assets/icons/SuccessIcon.svg";

// Definimos un prefijo único para evitar colisiones de estilos
const PREFIX = "SuccessMessage";

// Definimos las clases para los estilos del componente
const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  message: `${PREFIX}-message`,
  button: `${PREFIX}-button`,
};

// Usamos `styled` para aplicar estilos a los elementos HTML
const Root = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 400,
    textAlign: "center",
    padding: theme.spacing(4),
    backgroundColor: "white",
    borderRadius: "24px",
    boxShadow: theme.shadows[3],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.icon}`]: {
    fontSize: 50,
    color: "#4476B5",
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.message}`]: {
    marginTop: theme.spacing(1),
  },
  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    backgroundColor: "#4476B5",
    color: "white",
  },
}));

// Componente SuccessMessage
const SuccessMessage = ({ title, message, onClose }) => {
  return (
    <Root className={classes.root}>
      <img src={SuccessIcon} alt="Success Icon" className={classes.icon} />
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.message}>
        {message}
      </Typography>
      <Button variant="contained" onClick={onClose} className={classes.button}>
        Cerrar
      </Button>
    </Root>
  );
};

export default SuccessMessage;
