import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = "ConfirmDialog";

// Clases con prefijos personalizados
const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
  actions: `${PREFIX}-actions`,
  confirmButton: `${PREFIX}-confirmButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    borderRadius: "12px",
    padding: theme.spacing(2),
    minWidth: "320px",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  [`&.${classes.title}`]: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  [`&.${classes.content}`]: {
    padding: theme.spacing(2),
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  [`&.${classes.actions}`]: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center", // Centra los botones horizontalmente
    gap: theme.spacing(2), // Espacio entre botones
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  [`&.${classes.confirmButton}`]: {
    backgroundColor: "#4476B5",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: "#365f92", // Color de hover para botones primarios
    },
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  [`&.${classes.cancelButton}`]: {
    color: "#4476B5",
    borderColor: "#4476B5",
    "&:hover": {
      backgroundColor: "rgba(68, 118, 181, 0.15)", // Fondo translúcido
      borderColor: "#365F92", // Oscurecer un poco el borde al hacer hover
    },
  },
}));

const ConfirmDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = "Confirmar",
  cancelText = "Cancelar",
}) => {
  return (
    <StyledDialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <StyledDialogTitle className={classes.title}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={onCancel} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent className={classes.content}>
        <Typography>{message}</Typography>
      </StyledDialogContent>
      <StyledDialogActions className={classes.actions}>
        <CancelButton
          onClick={onCancel}
          variant="outlined"
          className={classes.cancelButton}
        >
          {cancelText}
        </CancelButton>
        <ConfirmButton
          onClick={onConfirm}
          variant="contained"
          autoFocus
          className={classes.confirmButton}
        >
          {confirmText}
        </ConfirmButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmDialog;
