import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function actualizarCuestionario(id, respuestas, markAsCompleted) {
  const accessToken = localStorage.getItem("currentToken");

  let output = { answers: respuestas };

  if (markAsCompleted) {
    output["completed"] = true;
  }

  const response = await fetch(`${URL}/reports/${id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(output),
  });

  const res = await response.json();
  checkResponse(res);
  return { response, res };
}
