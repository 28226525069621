import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const Header = (props) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3">Nuevo Cuestionario</Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
