import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as RightArrow } from "assets/icons/rightArrow.svg";

const PREFIX = "VerButton";

const classes = {
  root: `${PREFIX}-root`,
  startIcon: `${PREFIX}-startIcon`,
};

const StyledButton = styled(Button)(({ theme, maxHeight, maxWidth }) => ({
  [`&.${classes.root}`]: {
    textTransform: "none", // Desactiva las mayúsculas automáticas
    maxHeight: maxHeight || "29px", // Altura máxima configurable, valor por defecto 36px
    maxWidth: maxWidth || "60", // Anchura máxima configurable, valor por defecto "auto"
    marginRight: theme.spacing(1), // Margen derecho automático
    marginLeft: theme.spacing(1), // Margen izquierdo automático
    fontFamily: "'Inter', sans-serif", // Fuente Inter
    fontWeight: 600, // Peso 600 para texto
    fontSize: "14px", // Tamaño de fuente 14px
  },
  [`& .${classes.startIcon}`]: {
    marginRight: "0px", // Espaciado estándar entre el ícono y el texto
  },
}));

const VerButton = ({ onClick, children, maxHeight, maxWidth }) => {
  return (
    <StyledButton
      className={classes.root}
      endIcon={<RightArrow className={classes.startIcon} />}
      onClick={onClick}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      color="primary" // Usa el color primario del tema
      variant="contained" // Botón con estilo contenido
    >
      {children || "Ver"} {/* Texto predeterminado "Continuar" */}
    </StyledButton>
  );
};

export default VerButton;
