import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editarEspecialidadDeCuestionario(
  questionaryID,
  specialtyID,
) {
  const accessToken = localStorage.getItem("currentToken");

  const response = await fetch(
    `${URL}/specialties/questionary/${questionaryID}/specialty/`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Token ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ specialtyID }),
    },
  );

  const res = await response.json();
  checkResponse(res);
  return { response, res };
}
