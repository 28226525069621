import React from "react";
import { styled } from "@mui/material/styles";

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Radio,
} from "@mui/material";

const PREFIX = "QBoolean";

const classes = {
  root: `${PREFIX}-root`,
  option: `${PREFIX}-option`,
  optionRadio: `${PREFIX}-optionRadio`,
  optionDetails: `${PREFIX}-optionDetails`,
};

const StyledCard = styled(Card)(({ theme, isError }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    border: isError ? "2px solid red" : `1px solid ${theme.palette.divider}`, // Estilo condicional
  },

  [`& .${classes.option}`]: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0.8),
    maxWidth: 560,
  },

  [`& .${classes.optionRadio}`]: {
    margin: -10,
  },

  [`& .${classes.optionDetails}`]: {
    marginLeft: theme.spacing(1),
  },
}));

// Custom styled Radio
const CustomRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    borderRadius: "50%", // Ensure the circle shape
    transition: "transform 0.2s ease-in-out", // Smooth transition for scaling
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    color: theme.palette.primary.main, // Color when selected
    transform: "scale(1.2)", // Scale up the icon when selected
  },
  "& .MuiSvgIcon-root circle": {
    fill: theme.palette.background.default,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const QBoolean = (props) => {
  const { question, answer, questionNumber, answerQuestion, isError, ...rest } =
    props;
  const { text, options } = question;

  const handleChange = (idx) => {
    answerQuestion(questionNumber, idx);
  };

  return (
    <StyledCard {...rest} className={classes.root} isError={isError}>
      <CardHeader
        title={<StyledTypography variant="h6">{text}</StyledTypography>}
        style={{ padding: 0, marginBottom: "12px" }}
      />

      <CardContent style={{ padding: 0 }}>
        {options.map((option, idx) => (
          <div
            className={classes.option}
            key={"opcion" + idx + "pregunta" + questionNumber.toString()}
          >
            <CustomRadio
              {...rest}
              checked={answer === idx}
              className={classes.optionRadio}
              onClick={(_) => handleChange(idx)}
            />

            <div className={classes.optionDetails}>
              <StyledTypography gutterBottom variant="h5">
                {option}
              </StyledTypography>
            </div>
          </div>
        ))}
      </CardContent>
    </StyledCard>
  );
};

export default QBoolean;
