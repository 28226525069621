import React from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const PREFIX = "ChatSearchBar";

const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
  input: `${PREFIX}-input`,
};

const ChatSearchBar = ({ handleSearchChange, value }) => {
  const handleChange = (event) => {
    handleSearchChange(event); // Pasamos el evento completo para que lo maneje correctamente
  };

  return (
    <FormControl fullWidth>
      <TextField
        placeholder="Buscar"
        color="secondary"
        className={classes.textField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          className: classes.input,
        }}
        value={value}
        onChange={handleChange} // Asegurarse de pasar el evento completo aquí
      />
    </FormControl>
  );
};

export default ChatSearchBar;
