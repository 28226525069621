import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  button: `${PREFIX}-button`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(0),
    borderRadius: "24px",
  },
  [`& .${classes.card}`]: {
    marginBottom: theme.spacing(3),
    borderRadius: "24px",
  },
  [`& .${classes.cardContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(3),
  },
  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
    borderRadius: "24px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  [`& .${classes.title}`]: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.black,
    marginBottom: theme.spacing(2),
  },
}));

const Header = (props) => {
  const { className, ...rest } = props;

  const userData = useSelector((state) => state.userData.serverUserData);
  const today = new Date().toLocaleDateString();

  return (
    <Root {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" component="h2" className={classes.title}>
                👋 Buenos días {userData.first_name}
              </Typography>
              <Typography color="textSecondary">
                Completa el autorreporte de hoy {today}
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                component={RouterLink}
                to={"/autorreporte"}
              >
                Completar Autorreporte
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
