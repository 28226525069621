// Function to get patient requests
import { URL } from "../url";

export async function getPatientRequests(userID) {
  try {
    const accessToken = localStorage.getItem("currentToken"); // Retrieve the access token

    const response = await fetch(`${URL}/users/${userID}/requests/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`, // Add the access token to the headers
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data.requests; // Return the requests data
  } catch (error) {
    console.error("Failed to fetch patient requests:", error);
    return null; // Return null or handle the error as needed
  }
}
