import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function actualizarEVAPaciente(eva, patientID) {
  const accessToken = localStorage.getItem("currentToken");
  // const id = await localStorage.getItem("id").toString();
  const output = { base_pain: eva };
  const response = await fetch(`${URL}/users/${patientID}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(output),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return { response, res };
  }
}
