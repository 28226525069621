import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getAutoreportesUser(accessToken, id) {
  try {
    const response = await fetch(`${URL}/users/${id}/logs/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();
    if (checkResponse(response)) {
      return res.logs;
    }
  } catch (error) {
    return null;
  }
}
