import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function assignTasksToPatients(accessToken, TaskIds, patientIds) {
  const response = await fetch(`${URL}/patientTasks/assign`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      task_ids: TaskIds,
      patient_ids: patientIds,
    }),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
