import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function unfavoriteArticle(id, uid, accessToken) {
  const response = await fetch(`${URL}/users/${uid}/favorites/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      articleID: id,
    }),
  });
  if (checkResponse(response)) {
    return response;
  }
}
