import { REPORT_URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function createDownloadablePatientReport(userId, startDate, endDate) {

  const accessToken = localStorage.getItem("currentToken");

  const response = await fetch(`${REPORT_URL}/lambdaDiagnosticoPaciente`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ // Aquí el body debe estar en formato JSON
      user_id: userId, // Convertimos el ID a string por consistencia
      start_date: startDate.toString(),
      end_date: endDate.toString(),
    }),
  });

  const res = await response.json();

  if (checkResponse(res)) {
    return { response, res };
  } else {
    throw new Error("Error en la creación del reporte de diagnóstico");
  }
}
