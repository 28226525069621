import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editArticle(
  name,
  description,
  id,
  fileType,
  accessToken,
) {
  const response = await fetch(`${URL}/articles/${id}/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      article_name: name,
      text: description,
      file_type: fileType,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return res;
  }
}
