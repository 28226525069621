import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function postStatsUser(accessToken, id, time) {
  try {
    const response = await fetch(`${URL}/users/${id}/stats/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
      body: JSON.stringify({
        range: time,
      }),
    });
    const res = await response.json();
    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    return null;
  }
}
