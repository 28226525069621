import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getProfessionals(accessToken, userId) {
  try {
    const response = await fetch(`${URL}/users/${userId}/professionals`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();
    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    return null;
  }
}
