import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: "12px",
  "& .MuiAlert-message": {
    fontSize: "1rem",
    fontWeight: 500,
    whiteSpace: "pre-line",
  },
  "& .MuiAlert-icon": {
    fontSize: "24px",
  },
}));

const ErrorAlert = ({ open, message, severity = "error", onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        maxWidth: "80%",
        "& .MuiPaper-root": {
          maxWidth: "100%",
        },
      }}
    >
      <StyledAlert
        onClose={onClose}
        severity={severity}
        variant="filled"
        elevation={6}
      >
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

export default ErrorAlert;
