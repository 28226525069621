import React from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

const PREFIX = "SearchBar";

const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%", // Asegura que ocupe todo el ancho disponible
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`&.${classes.textField}`]: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "100px", // Borde redondeado
      backgroundColor: "white", // Fondo blanco
      "& fieldset": {
        borderRadius: "100px", // Borde redondeado en el fieldset
        borderColor: theme.palette.grey[400], // Color del borde por defecto
      },
      "&:hover fieldset": {
        borderColor: theme.palette.bluePrimary.main, // Color del borde al hacer hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.bluePrimary.main, // Color del borde cuando está en focus
      },
    },
  },
}));

const SearchBar = (props) => {
  const { handleSearchChange, value } = props;

  function handleChange(event) {
    if (!handleSearchChange) return;
    handleSearchChange(event.target.value);
  }

  return (
    <StyledFormControl className={classes.root}>
      <StyledTextField
        className={classes.textField}
        placeholder="Buscar"
        color="secondary"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={value}
        onChange={handleChange}
      />
    </StyledFormControl>
  );
};

export default SearchBar;
