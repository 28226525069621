import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editarCuestionario(id, cuestionarioData) {
  const accessToken = localStorage.getItem("currentToken");

  const response = await fetch(`${URL}/questionaries/${id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cuestionarioData),
  });

  const res = await response.json();
  checkResponse(res);
  return { response, res };
}
