import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material/styles';
import {
  Box,
  Fab,
  Paper,
  IconButton,
  Typography,
  Avatar,
  TextField,
  Button,
  CircularProgress,
  Zoom,
  Fade
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import { sendMessageToChatBot } from 'utils/post';

const MarkdownContent = styled('div')(({ theme }) => ({
  '& p': {
    margin: 0,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: theme.typography.body2.fontWeight,
  },
  '& ul, & ol': {
    margin: '0.5em 0',
    paddingLeft: '1.5em',
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
  },
  '& code': {
    backgroundColor: theme.palette.grey[100],
    padding: '0.2em 0.4em',
    borderRadius: '3px',
    fontSize: '85%',
    fontFamily: 'inherit',
  },
  '& pre': {
    backgroundColor: theme.palette.grey[100],
    padding: '0.5em',
    borderRadius: '4px',
    overflow: 'auto',
    '& code': {
      backgroundColor: 'transparent',
      padding: 0,
      fontFamily: 'inherit',
    }
  },
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 24,
  right: 24,
  zIndex: 9999,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'scale(1.1)',
  },
  transition: 'transform 0.3s ease',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    backgroundColor: theme.palette.background.paper,
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
    },
  },
}));

const MessageBubble = ({ message }) => {
  const isUser = message.sender === 'user';
  
  return (
    <Fade in={true} timeout={500}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: isUser ? 'flex-end' : 'flex-start',
          mb: 2,
          px: 2,
        }}
      >
        {!isUser && (
          <Avatar 
            sx={{ 
              bgcolor: 'primary.main',
              width: 32,
              height: 32,
              mr: 1,
              mt: 0.5
            }}
          >
            <SmartToyOutlinedIcon sx={{ fontSize: 20 }} />
          </Avatar>
        )}
        <Box
          sx={{
            maxWidth: '70%',
            p: 2,
            // AQUÍ es donde se cambia el color de fondo y texto
            bgcolor: isUser ? 'primary.light' : 'grey.100',  // Cambiado de 'primary.main'
            color: isUser ? 'primary.dark' : 'text.primary', // Cambiado de 'white'
            borderRadius: isUser ? '20px 20px 4px 20px' : '20px 20px 20px 4px',
            boxShadow: 2,
            position: 'relative',
            '&::before': isUser ? {
              content: '""',
              position: 'absolute',
              bottom: 0,
              right: -10,
              width: 20,
              height: 20,
              // También hay que cambiar el color aquí para el "pico" de la burbuja
              bgcolor: 'primary.light',  // Cambiado de 'primary.main'
              borderRadius: '0 0 0 20px',
              zIndex: -1,
            } : {},
          }}
        >
          {isUser ? (
            <Typography variant="body2">{message.text}</Typography>
          ) : (
            <MarkdownContent>
              <ReactMarkdown>{message.text}</ReactMarkdown>
            </MarkdownContent>
          )}
        </Box>
        {isUser && (
          <Avatar 
            sx={{ 
              bgcolor: 'secondary.main',
              width: 32,
              height: 32,
              ml: 1,
              mt: 0.5
            }}
          >
            U
          </Avatar>
        )}
      </Box>
    </Fade>
  );
};

const MessagesContainer = ({ messages }) => {
  const containerRef = React.useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        backgroundColor: '#f8f9fa',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.2)',
          borderRadius: '4px',
        },
        py: 2,
      }}
    >
      {messages.map((message, index) => (
        <MessageBubble key={index} message={message} />
      ))}
    </Box>
  );
};


const SidebarChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [conversationStarted, setConversationStarted] = useState(false);
  const [threadId, setThreadId] = useState('');
  const [service, setService] = useState('');
  const [convStatus, setConvStatus] = useState('');

  const userId = localStorage.getItem('id');
  const accessToken = localStorage.getItem('currentToken');
  const isAuthenticated = userId && accessToken;

  // Si no está autenticado, no renderizar nada
  if (!isAuthenticated) {
    return null;
  }

  const addMessage = async (message) => {
    const messageLines = message.text.split('\n');
    const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    for (const line of messageLines) {
      if (line.trim() !== '') {
        const newMessage = { ...message, text: line };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        await pause(500);
      }
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      addMessage({ sender: 'user', text: inputValue });
      setInputValue('');
      setIsSending(true);

      try {
        const response = await sendMessageToChatBot(
          inputValue,
          userId,
          threadId,
          service,
          accessToken,
        );

        if (response && response.message) {
          addMessage({ sender: 'bot', text: response.message });
          setConvStatus(response.status);
        } else {
          addMessage({
            sender: 'bot',
            text: 'Lo siento, hubo un problema al procesar tu mensaje. Por favor, intenta nuevamente.',
          });
        }
      } catch (error) {
        console.error('Error sending message:', error);
        addMessage({
          sender: 'bot',
          text: 'Ocurrió un error al enviar tu mensaje. Por favor, verifica tu conexión e intenta nuevamente.',
        });
      }
      
      setIsSending(false);
    }
  };

  const startAutoreporte = async (currentThreadId) => {
    if (isSending) return;
    setIsSending(true);

    try {
      const response = await sendMessageToChatBot(
        'starting autorreporte',
        userId,
        currentThreadId,
        'auto-reporte',
        accessToken,
      );

      if (response && response.message) {
        addMessage({ sender: 'bot', text: response.message });
        setConvStatus(response.status);
      } else {
        addMessage({
          sender: 'bot',
          text: 'Hubo un problema al iniciar el autorreporte. Por favor, intenta nuevamente.',
        });
      }
    } catch (error) {
      console.error('Error starting autoreporte:', error);
      addMessage({
        sender: 'bot',
        text: 'No se pudo iniciar el autorreporte. Por favor, intenta nuevamente.',
      });
    }

    setIsSending(false);
  };

  const handleStartConversation = (selectedService) => () => {
    setConversationStarted(true);
    setService(selectedService);

    const newThreadId = `thread_${userId}_${Date.now()}`;
    setThreadId(newThreadId);

    if (selectedService === 'embedding') {
      addMessage({ 
        sender: 'bot', 
        text: 'Hola, soy el asistente especializado en dolor crónico. ¿En qué puedo ayudarte?' 
      });
    } else if (selectedService === 'auto-reporte') {
      addMessage({ 
        sender: 'bot', 
        text: 'Hola, iniciaremos el Autorreporte.' 
      });
      setTimeout(() => startAutoreporte(newThreadId), 0);
    }
  };
  
  const resetConversation = () => {
    setConversationStarted(false);
    setConvStatus('');
    setMessages([]);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (conversationStarted) {
      resetConversation();
    }
  };

  return (
    <>
      {!isOpen && (
        <Zoom in={true}>
          <StyledFab
            color="primary"
            onClick={() => setIsOpen(true)}
          >
            <ChatIcon />
          </StyledFab>
        </Zoom>
      )}

      <Paper
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: 360,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          zIndex: 9999,
          borderRadius: 0,
          boxShadow: 3,
          overflow: 'hidden',
        }}
      >
        {/* Header */}
        <Box sx={{ 
          p: 2, 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'primary.main',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
        }}>
          <IconButton onClick={handleClose} sx={{ mr: 1, color: 'white' }}>
            <ArrowBackIcon />
          </IconButton>
          <Avatar sx={{ mr: 2, bgcolor: 'white' }}>
            <SmartToyOutlinedIcon sx={{ color: 'primary.main' }} />
          </Avatar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Agente Alivia
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Subheader */}
        <Box sx={{ 
          p: 2, 
          bgcolor: 'primary.light',
          color: 'white',
          textAlign: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Especialista en dolor crónico
          </Typography>
          <Typography variant="caption" sx={{ 
            display: 'block',
            bgcolor: 'rgba(0,0,0,0.1)',
            p: 1,
            borderRadius: 1,
          }}>
            La información generada es por una IA, tener precaución
          </Typography>
        </Box>

        <MessagesContainer messages={messages} />

        {/* Input Area */}
        <Box sx={{ 
          p: 2, 
          borderTop: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
        }}>
          {convStatus === 'completed' ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                La conversación ha finalizado
              </Typography>
              <Button 
                variant="contained"
                onClick={resetConversation}
                fullWidth
                sx={{
                  borderRadius: 20,
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
              >
                Iniciar Nueva Conversación
              </Button>
            </Box>
          ) : !conversationStarted ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleStartConversation('embedding')}
                fullWidth
                sx={{
                  borderRadius: 20,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  py: 1.5,
                }}
              >
                Consultar sobre Dolor Crónico
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleStartConversation('auto-reporte')}
                fullWidth
                sx={{
                  borderRadius: 20,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  py: 1.5,
                }}
              >
                Realizar Autorreporte
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <StyledTextField
                fullWidth
                size="small"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                placeholder="Escribe tu mensaje..."
                disabled={isSending}
                sx={{ flex: 1 }}
              />
              <IconButton 
                color="primary"
                onClick={handleSendMessage}
                disabled={isSending}
                sx={{
                  bgcolor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                  '&.Mui-disabled': {
                    bgcolor: 'action.disabledBackground',
                  },
                }}
              >
                {isSending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default SidebarChatbot;