import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "#000000";

const hexToRgba = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

let palette = {
  black,
  white,
  primary: {
    main: "#4476B5",
    light: "#4476B51A",
    dark: "#173F8A",
    contrastText: white,
  },
  secondary: {
    main: white,
    light: white,
    dark: "#C2E2FF",
    contrastText: "#0176DE",
  },
  error: {
    main: colors.red[600],
    dark: colors.red[900],
    light: colors.red[400],
    contrastText: white,
  },
  warning: {
    main: colors.orange[600],
    dark: colors.orange[900],
    light: colors.orange[400],
    contrastText: white,
  },
  info: {
    main: colors.blue[600],
    dark: colors.blue[900],
    light: colors.blue[400],
    contrastText: white,
  },
  success: {
    main: colors.green[600],
    dark: colors.green[900],
    light: colors.green[400],
    contrastText: white,
  },
  personal: {
    main: "#802e87",
    light: "#802e87",
    dark: colors.blue["A400"],
    contrastText: white,
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: "#F4F6F8",
    secondary: "#FAFAFA",
    paper: white,
  },
  divider: colors.grey[200],
  graph: {
    pain: {
      light: hexToRgba("#D82821", 0.1), // Lighter color with 50% transparency
      dark: hexToRgba("#D82821", 1), // Darker color with 80% transparency
    },
    sleep: {
      light: hexToRgba("#10386B", 0.1), // Lighter color with 50% transparency
      dark: hexToRgba("#10386B", 1), // Darker color with 80% transparency
    },
    mood: {
      light: hexToRgba("#9D00AE", 0.1), // Lighter color with 50% transparency
      dark: hexToRgba("#9D00AE", 1), // Darker color with 80% transparency
    },
  },
  bluePrimary: {
    main: "#4476B5",
    light: "#4476B5",
    dark: "#4476B5",
    contrastText: white,
  },
};

export default palette;
