import React, { Suspense } from "react";
import { styled } from "@mui/material/styles";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";

const PREFIX = "Auth";

const classes = {
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    height: "100%",
    paddingTop: 0,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
    },
  },
}));

const Auth = (props) => {
  const { route } = props;

  return (
    <Root>
      {/* <Topbar /> */}
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </Root>
  );
};

Auth.propTypes = {
  route: PropTypes.object,
};

export default Auth;
