import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function patchExamInfo(medicalExamID, updatedData) {
  try {
    const accessToken = localStorage.getItem("currentToken"); // Obtener el token del localStorage

    const response = await fetch(`${URL}/medicalExams/${medicalExamID}`, {
      method: "PATCH",
      headers: {
        Authorization: `Token ${accessToken}`, // Autorización
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData), // Datos a actualizar
      //   Atributos que se pueden cambiar:
      //   indications,
      //   name,
      //   favorite,
      //   file_type, --> hacer match con el documento a actualizar.
    });

    const res = await response.json();

    if (checkResponse(response)) {
      return res; // Retorna la respuesta si es exitosa
    } else {
      throw new Error(
        `Error al actualizar la información del examen: ${res.message || "Error desconocido"}`,
      );
    }
  } catch (error) {
    console.error("Error en patchExamInfo:", error);
    return null; // Retorna null en caso de error
  }
}
