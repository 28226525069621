import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import questionaryIcon from "assets/icons/questionaryIcon.svg";
import rightArrow from "assets/icons/rightArrow.svg";

const PREFIX = "CuestionarioCard";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  details: `${PREFIX}-details`,
  label: `${PREFIX}-label`,
  textLabel: `${PREFIX}-textLabel`,
  viewButton: `${PREFIX}-viewButton`,
  questionaryTitle: `${PREFIX}-questionaryTitle`,
  icon: `${PREFIX}-icon`,
  infoRow: `${PREFIX}-infoRow`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%", // La tarjeta ocupará el ancho completo del contenedor hasta el máximo especificado
    maxWidth: 348, // Ancho máximo de 348px
    height: "auto", // Permitir que la altura sea automática
    maxHeight: 247,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: "24px",
  },
  [`& .${classes.header}`]: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.content}`]: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  [`& .${classes.label}`]: {
    fontFamily: "Inter",
    fontWeight: 600, // Semibold
    fontSize: "14px",
    color: "#2E2E2E",
  },
  [`& .${classes.textLabel}`]: {
    fontFamily: "Inter",
    fontWeight: 500, // Medium
    fontSize: "14px",
    color: "#2E2E2E",
  },
  [`& .${classes.infoRow}`]: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  [`& .${classes.viewButton}`]: {
    color: "#FFFFFF", // Texto en blanco
    marginTop: theme.spacing(3), // Espaciado superior
    borderRadius: "8px", // Bordes redondeados
    padding: theme.spacing(0.5, 2), // Espaciado interno
    paddingRight: 8,
    paddingLeft: 8,
    minWidth: "60px", // Ancho mínimo para adaptarse mejor a la flecha
    fontFamily: "Inter", // Fuente Inter
    fontWeight: 600, // Semibold
    fontSize: "14px", // Tamaño de fuente 14px
    boxShadow: "none", // Sin sombra
  },

  [`& .${classes.questionaryTitle}`]: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    color: "#2E2E2E",
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0),
    textTransform: "capitalize", // Asegura que cada palabra comience con mayúscula
  },
  [`& .${classes.icon}`]: {
    width: 18,
    height: 18,
  },
}));

const CuestionarioCard = (props) => {
  const { project, className, ...rest } = props;

  const link_to =
    "/cuestionario/" + project.questionaryID + "/" + project.id + "/";

  return (
    <StyledCard {...rest} className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <img
          src={questionaryIcon}
          alt="Questionary Icon"
          className={classes.icon}
        />
        <Typography className={classes.questionaryTitle}>
          {project.questionary_name || "Nombre del cuestionario"}
        </Typography>
      </div>
      <CardContent className={classes.content}>
        <div className={classes.details}>
          <div className={classes.infoRow}>
            <Typography className={classes.label}>Médico Tratante:</Typography>
            <Typography>
              {project.first_name && project.last_name
                ? `${project.first_name} ${project.last_name}`
                : "No identificado"}
            </Typography>
          </div>
          <div className={classes.infoRow}>
            <Typography className={classes.label}>Fecha:</Typography>
            <Typography>
              {project.createdAt
                ? new Date(project.createdAt).toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })
                : "24/05/24"}
            </Typography>
          </div>
        </div>
      </CardContent>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Button
            variant="contained"
            className={classes.viewButton}
            component={RouterLink}
            to={link_to}
          >
            Responder
            <img
              src={rightArrow}
              alt="Right Arrow"
              style={{ marginLeft: 8 }}
            />{" "}
            {/* Icono de flecha */}
          </Button>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

CuestionarioCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.shape({
    questionaryID: PropTypes.string,
    id: PropTypes.string,
    questionary_name: PropTypes.string,
    textLabel: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default CuestionarioCard;
