import React, { createContext, useContext, useState } from "react";
import ErrorAlert from "../components/ErrorAlert/ErrorAlert";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "error", // 'error' | 'success'
  });

  const showError = (message) => {
    setAlert({
      open: true,
      message,
      severity: "error",
    });
  };

  const showSuccess = (message) => {
    setAlert({
      open: true,
      message,
      severity: "success",
    });
  };

  const hideAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <AlertContext.Provider value={{ showError, showSuccess }}>
      {children}
      <ErrorAlert
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={hideAlert}
      />
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
