import { URL } from "../url";
import { checkResponse } from "../checkResponse";

/**
 * Asigna una lista de pacientes a un artículo.
 * @param {string} article_id - El ID del artículo.
 * @param {string} accessToken - El token de acceso para autenticación.
 * @param {array} patients - Lista de IDs de pacientes a asignar.
 * @param {boolean} [exclusive=false] - Si es true, reemplaza los pacientes existentes. Si es false, agrega sin eliminar.
 * @returns {object} La respuesta JSON de la API si la solicitud es exitosa.
 */
export async function assignPatientToArticle(
  article_id,
  accessToken,
  patients,
  exclusive = false,
  doctorID,
) {
  try {
    const response = await fetch(`${URL}/articles/${article_id}/patients`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
      body: JSON.stringify({
        patients, // Lista de IDs de pacientes
        exclusive, // Parámetro opcional para reemplazo completo
        doctorID, // ID del doctor
      }),
    });

    const res = await response.json();

    if (checkResponse(response)) {
      return res; // Retorna la respuesta si es exitosa
    } else {
      throw new Error(
        `Failed to assign patients: ${res.message || "Unknown error"}`,
      );
    }
  } catch (error) {
    console.error("Error in assignPatientToArticle:", error);
    throw error;
  }
}
