import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function actualizarDatosUsuario(datos, patientID) {
  const accessToken = localStorage.getItem("currentToken");
  // const id = await localStorage.getItem("id").toString();
  const response = await fetch(`${URL}/users/${patientID}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return {
      ok: response.ok,
      body: res.body,
    };
  }
}
