import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as TrashTagsIcon } from "assets/icons/TrashTagsIcon.svg";

const PREFIX = "DeleteButton";

const classes = {
  root: `${PREFIX}-root`,
  startIcon: `${PREFIX}-startIcon`,
};

const StyledButton = styled(Button)(({ theme, maxHeight, maxWidth }) => ({
  [`&.${classes.root}`]: {
    color: "#D82821",
    backgroundColor: "rgba(216, 40, 33, 0.1)",
    textTransform: "none",
    borderRadius: theme.shape.borderRadius,
    marginLeft: "auto",
    marginRight: theme.spacing(4.5), // Equivalente a 36px
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    fontSize: "14px",
    maxHeight: maxHeight || "29px", // Valor por defecto si no se proporciona
    maxWidth: maxWidth || "91px", // Valor por defecto si no se proporciona
    "&:hover": {
      backgroundColor: "rgba(216, 40, 33, 0.2)",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    },
  },
  [`& .${classes.startIcon}`]: {
    marginRight: theme.spacing(0), // Asegura espacio entre el ícono y el texto
  },
}));

const DeleteButton = ({ onClick, children, maxHeight, maxWidth }) => {
  return (
    <StyledButton
      className={classes.root}
      startIcon={<TrashTagsIcon className={classes.startIcon} />}
      onClick={onClick}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
    >
      {children || "Eliminar"}
    </StyledButton>
  );
};

export default DeleteButton;
