// Function to post a patient request
import { URL } from "../url";

export async function postPatientRequest(patientID, doctorID) {
  try {
    const accessToken = localStorage.getItem("currentToken"); // Retrieve the access token
    const response = await fetch(`${URL}/users/${patientID}/patient-request/`, {
      // Adjust the endpoint if necessary
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`, // Add the access token to the headers
      },
      body: JSON.stringify({ patientID, doctorID }), // Ensure the body matches the Postman request
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    await response.json();
    return response;
  } catch (error) {
    console.error("Failed to create patient request:", error);
    throw error;
  }
}
