import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function createMedicineCalendar(accessToken, patientID, entries) {
  try {
    // Realizar la solicitud
    const fetchResponse = await fetch(`${URL}/medicineCalendar/${patientID}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`, // El token de acceso
        "Content-Type": "application/json", // Configurar como JSON
      },
      body: JSON.stringify(entries), // Serializar los datos
    });

    const responseData = await fetchResponse.json();

    if (checkResponse(fetchResponse)) {
      return responseData;
    } else {
      console.error(
        `Request failed with status ${fetchResponse.status}: ${
          responseData.message || "Unknown error"
        }`,
      );
      return null;
    }
  } catch (error) {
    console.error("Error in createMedicineCalendar:", error);
    return null;
  }
}
