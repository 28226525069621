import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function desasignarPaciente(patient_id, accessToken, user_id) {
  const response = await fetch(`${URL}/users/${user_id}/patients/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      patientID: patient_id,
    }),
  });
  if (checkResponse(response)) {
    return response;
  }
}
