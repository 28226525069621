import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function createNewTag(tag_name) {
  const accessToken = localStorage.getItem("currentToken");
  const response = await fetch(`${URL}/tags/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({ tag_name: tag_name }),
  });
  if (checkResponse(response)) {
    return response;
  }
}
