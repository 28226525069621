import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as EditIcon } from "assets/icons/editIcon.svg";

const PREFIX = "EditButton";

const classes = {
  root: `${PREFIX}-root`,
  startIcon: `${PREFIX}-startIcon`,
};

const StyledButton = styled(Button)(({ theme, maxHeight, maxWidth }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.primary.main, // Texto con el color del tema (primary.main)
    backgroundColor: "#ECF1F8", // Fondo del botón
    textTransform: "none", // Desactiva la transformación del texto a mayúsculas
    borderRadius: theme.shape.borderRadius, // Borde redondeado basado en el tema
    marginLeft: "auto",
    marginRight: theme.spacing(1), // Equivalente a 36px
    fontFamily: "'Inter', sans-serif", // Fuente Inter
    fontWeight: 600, // Peso 600 para texto
    fontSize: "14px", // Tamaño de fuente 14px
    maxHeight: maxHeight || "29px", // Altura máxima configurable
    maxWidth: maxWidth || "91px", // Anchura máxima configurable
    "&:hover": {
      backgroundColor: "#E0E7F4", // Fondo más oscuro en hover
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
    },
  },
  [`& .${classes.startIcon}`]: {
    marginRight: theme.spacing(0), // Espaciado entre el icono y el texto
  },
}));

const EditButton = ({ onClick, children, maxHeight, maxWidth }) => {
  return (
    <StyledButton
      className={classes.root}
      startIcon={<EditIcon className={classes.startIcon} />}
      onClick={onClick}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
    >
      {children || "Editar"} {/* Texto predeterminado es "Editar" */}
    </StyledButton>
  );
};

export default EditButton;
