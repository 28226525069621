import { URL } from "../url";
import { checkResponse } from "../checkResponse";

/**
 * Fetches the medicine calendar for a specific patient.
 * @param {string} accessToken - The access token for authentication.
 * @param {string} patientID - The ID of the patient whose medicine calendar is being fetched.
 * @returns {Promise<Object|null>} - The medicine calendar data or null in case of an error.
 */
export async function getMedicineCalendarByPatient(accessToken, patientID) {
  try {
    // Send the request
    const fetchResponse = await fetch(`${URL}/medicineCalendar/${patientID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`, // Include the access token
        "Content-Type": "application/json", // Ensure JSON format
      },
    });

    const responseData = await fetchResponse.json();

    if (checkResponse(fetchResponse)) {
      return responseData;
    } else {
      console.error(
        `Request failed with status ${fetchResponse.status}: ${
          responseData.message || "Unknown error"
        }`,
      );
      return null;
    }
  } catch (error) {
    console.error("Error in getMedicineCalendarByPatient:", error);
    return null;
  }
}
