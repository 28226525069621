import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MailIcon from "@mui/icons-material/MailOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const navigationConfig = {
  professional: {
    title: "Doctor",
    pages: [
      {
        title: "Inicio",
        href: "/inicio",
        icon: SpaceDashboardOutlinedIcon,
      },
      {
        title: "Mis Pacientes",
        href: "/pacientes",
        icon: PeopleIcon,
      },
      {
        title: "Crisis",
        href: "/crisis",
        icon: NotificationsActiveIcon,
      },
      {
        title: "Archivos",
        href: "/archivos",
        icon: FolderIcon,
      },
      {
        title: "Cuestionarios",
        href: "/cuestionariosdoctor",
        icon: ListAltIcon,
      },
      {
        title: "Tareas",
        href: "/tareas",
        icon: TaskOutlinedIcon,
      },
      {
        title: "Mensajería",
        href: "/mensajeria",
        icon: MailIcon,
      },
      {
        title: "Mi Cuenta",
        href: "/cuenta",
        icon: AccountBoxIcon,
      },
      // {
      //   title: "Invitación Nuevo Paciente",
      //   icon: PersonAddIcon,
      //   href: "/new-paciente",
      // },
    ],
  },
  admin: {
    title: "Administrador",
    pages: [
      {
        title: "Inicio",
        href: "/inicio",
        icon: DashboardIcon,
      },
      {
        title: "Mis Pacientes",
        href: "/pacientes",
        icon: PeopleIcon,
      },
      {
        title: "Archivos",
        href: "/archivos",
        icon: FolderIcon,
      },
      {
        title: "Cuestionarios",
        href: "/cuestionariosdoctor",
        icon: ListAltIcon,
      },
      {
        title: "Mensajería",
        href: "/mensajeria",
        icon: MailIcon,
      },
      {
        title: "Especialidades",
        href: "/specialties",
        icon: GroupAddIcon,
      },
      {
        title: "Mi Cuenta",
        href: "/cuenta",
        icon: AccountBoxIcon,
      },
      {
        title: "Nuevo Usuario",
        icon: PersonAddIcon,
        children: [
          {
            title: "Invitación nuevo Tratante",
            href: "/new-tratante",
          },
          {
            title: "Invitación nuevo Paciente",
            href: "/new-paciente",
          },
        ],
      },
    ],
  },
  patient: {
    title: "Paciente",
    pages: [
      {
        title: "Inicio",
        href: "/inicio",
        icon: HomeIcon,
      },
      {
        title: "Autorreporte",
        href: "/autorreporte",
        icon: ListAltIcon,
      },
      {
        title: "Mi Diagnóstico",
        href: "/mi-diagnostico",
        icon: AssignmentIcon,
      },
      // {
      //   title: "Mis Medicamentos",
      //   href: "/mi-diagnostico/medicamentos/calendario",
      //   icon: MedicationOutlinedIcon,
      // },
      // {
      // //   title: "Mis Tareas",
      // //   href: "/tareas",
      // //   icon: TaskIcon
      // // },
      {
        title: "Archivos",
        href: "/archivos",
        icon: FolderIcon,
      },
      {
        title: "Cuestionarios",
        href: "/cuestionarios",
        icon: ListAltIcon,
      },
      {
        title: "Mensajería",
        href: "/mensajeria",
        icon: MailIcon,
      },
      // {
      //   title: "Mi Cuenta",
      //   href: "/account",
      //   icon: AccountBoxIcon,
      // },
    ],
  },
};

export default navigationConfig;
