import React, { useState } from "react";
import { QBoolean, QScale, QText } from "../controls/";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Button, Typography, FormHelperText } from "@mui/material";
import { useHistory } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
  backgroundColor: "white",
  padding: theme.spacing(2),
  borderRadius: "24px",
  boxShadow: theme.shadows[1],
}));

const Question = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: "600",
}));

const Answer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
}));

function Questionnaire(props) {
  const { questionary, updateAnswers, validateOnSubmit, ...rest } = props;
  const history = useHistory();

  const {
    questionary_name: title,
    questions,
    answers: defaultAnswers,
  } = questionary;

  // Aseguramos que las respuestas iniciales sean `null` si no hay valores definidos
  const [answers, setAnswers] = useState(
    defaultAnswers && defaultAnswers.length === questions.length
      ? defaultAnswers
      : Array(questions.length).fill(null),
  );

  const [validationErrors, setValidationErrors] = useState([]);

  function answerQuestion(questionNumber, answer) {
    // Actualizamos las respuestas y forzamos la reactividad
    const newAnswers = [...answers];
    newAnswers[questionNumber] = answer;
    setAnswers(newAnswers);

    // Sincronizamos con el estado padre si es necesario
    if (updateAnswers) {
      updateAnswers(newAnswers);
    }

    // Eliminamos errores si la pregunta tiene respuesta
    if (validationErrors.includes(questionNumber) && answer !== null) {
      setValidationErrors(validationErrors.filter((q) => q !== questionNumber));
    }
  }

  function validate() {
    const errors = [];
    questions.forEach((pregunta, num) => {
      // Validamos solo las preguntas de tipo "options" y "scale"
      if (
        (pregunta.value === "options" || pregunta.value === "scale") &&
        answers[num] == null
      ) {
        errors.push(num);
      }
    });
    setValidationErrors(errors);
    return errors.length === 0;
  }

  // Pasamos la función de validación al padre
  if (validateOnSubmit) {
    validateOnSubmit(validate);
  }

  return (
    <Root>
      <Header>
        <Button
          onClick={() => history.goBack()}
          startIcon={<ArrowBackIosNewRoundedIcon />}
        ></Button>
        <Typography
          variant="h4"
          color="textPrimary"
          sx={{
            fontWeight: 600,
            fontSize: "24px",
            textTransform: "capitalize",
            margin: "0px",
          }}
        >
          {title}
        </Typography>
      </Header>

      {questions.map((pregunta, num) => {
        const key = `control-${num}`;
        const isError = validationErrors.includes(num);

        return (
          <Question key={key}>
            {pregunta.value === "options" ? (
              <>
                <QBoolean
                  {...rest}
                  answer={answers[num]}
                  question={pregunta}
                  questionNumber={num}
                  answerQuestion={answerQuestion}
                  isError={isError} // Pasamos el prop isError
                />
                {isError && (
                  <FormHelperText
                    sx={{ color: "red", marginLeft: "16px", marginTop: "8px" }}
                  >
                    Por favor selecciona una respuesta.
                  </FormHelperText>
                )}
              </>
            ) : pregunta.value === "scale" ? (
              <>
                <QScale
                  {...rest}
                  answer={answers[num]}
                  question={pregunta}
                  questionNumber={num}
                  answerQuestion={answerQuestion}
                  className={Answer}
                  isError={isError} // Pasamos el prop isError
                />
                {isError && (
                  <FormHelperText
                    sx={{ color: "red", marginLeft: "16px", marginTop: "8px" }}
                  >
                    Por favor selecciona una respuesta.
                  </FormHelperText>
                )}
              </>
            ) : (
              <QText
                {...rest}
                answer={answers[num]}
                question={pregunta}
                questionNumber={num}
                answerQuestion={answerQuestion}
                className={Answer}
              />
            )}
          </Question>
        );
      })}
    </Root>
  );
}

export { Questionnaire };
