import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function actualizarAutoreporte(id, lista, booleano) {
  const accessToken = localStorage.getItem("currentToken");
  let output = { answers: lista };
  if (booleano) {
    output["completed"] = true;
  }
  const response = await fetch(`${URL}/logs/${id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(output),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return { response, res };
  }
}
