import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import NewCalendarEventIcon from "assets/icons/NewCalendarEvent.svg";
import HelpCircleIcon from "assets/icons/help-circle.svg";
import { getNotifications } from "utils/fetch/getNotifications"; // Import the function
import { getReportId } from "utils/fetch/getReportId"; // Import the function

const PREFIX = "Overview-CardNotifications";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({}));

const CardNotifications = (props) => {
  const rootClasses = props.rootClasses;
  const userData = props.userData;

  const [notifications, setNotifications] = useState([]); // State to store notifications

  useEffect(() => {
    async function fetchNotifications() {
      const accessToken = localStorage.getItem("currentToken");
      const userID = localStorage.getItem("id");

      try {
        const fetchedNotifications = await getNotifications(
          accessToken,
          userID,
        );

        setNotifications(fetchedNotifications); // Save notifications to state

        for (const notification of fetchedNotifications) {
          if (notification.type === "report" && notification.reportID) {
            await getReportId(notification.reportID);
          } else if (
            notification.type === "task" &&
            notification.patientTaskID
          ) {
          }
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }

    fetchNotifications();
  }, []); // Empty dependency array to run once on component mount

  return (
    <Root className={classes.root}>
      <Card className={rootClasses.card}>
        <CardHeader
          title={<Typography variant="h3">Notificaciones</Typography>}
        />
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {notifications
              .slice(0, 3) // Limit to the first 3 notifications
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by most recent
              .map((notification, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {notification.type === "report" ? (
                    <img
                      src={HelpCircleIcon}
                      alt="Help Circle"
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <img
                      src={NewCalendarEventIcon}
                      alt="New Calendar Event"
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                  <Typography variant="body1">
                    {notification.type === "report" ? (
                      <>
                        Te han asignado un nuevo cuestionario.
                        <br />
                        <RouterLink
                          // to={`/profile/${userData.id}/questionaries`}
                          to={`/mi-diagnostico/cuestionarios`}
                          style={{
                            textDecoration: "underline",
                            color: "#DE9000",
                          }}
                        >
                          Ir a responderlo
                        </RouterLink>
                      </>
                    ) : notification.type === "task" ? (
                      <>
                        Te han asignado una nueva tarea.
                        <br />
                        <RouterLink
                          // to={`/profile/${userData.id}/tasks`}
                          to={`/mi-diagnostico/tareas`}
                          style={{
                            textDecoration: "underline",
                            color: "#35A000",
                          }}
                        >
                          Ir a ver el detalle
                        </RouterLink>
                      </>
                    ) : null}
                  </Typography>
                </Box>
              ))}
          </Box>
        </CardContent>
      </Card>
    </Root>
  );
};

CardNotifications.propTypes = {
  rootClasses: PropTypes.object,
  userData: PropTypes.object,
  setIsSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default CardNotifications;
