// Obtener la variable ENVIRONMENT del archivo .env
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
// Configuración de las URLs según el entorno
export const URL =
  ENVIRONMENT === "development"
    ? "http://localhost:3000"
    : "https://whx3z4mv39.execute-api.us-east-1.amazonaws.com/production/api";

export const REPORT_URL =
  ENVIRONMENT === "development"
    ? "https://6uc8hp93te.execute-api.us-east-1.amazonaws.com/production"
    : "https://6uc8hp93te.execute-api.us-east-1.amazonaws.com/production";

export const CHATBOT_URL =
  ENVIRONMENT === "development"
    ? "https://9k39dp1z09.execute-api.us-east-1.amazonaws.com/test/langgraph"
    : "https://9k39dp1z09.execute-api.us-east-1.amazonaws.com/test/langgraph";

export const WS_URL =
  ENVIRONMENT === "development"
    ? "ws://localhost:3000"
    : "https://whx3z4mv39.execute-api.us-east-1.amazonaws.com";

export const WS_URL_PATH = ENVIRONMENT === "development" ? "" : "/socket.io/";
