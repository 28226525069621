import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getWearableData(patientId) {
  const accessToken = localStorage.getItem("currentToken");

  const response = await fetch(`${URL}/users/${patientId}/watchdata/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
  });
  if (checkResponse(response)) {
    const res = await response.json();
    return { res, response };
  }
}
