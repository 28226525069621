import React, { createContext, useRef, useEffect } from "react";

export const SocketContext = createContext();

const SocketContextProvider = ({ children }) => {
  const socket = useRef(null);
  const listeners = useRef([]);

  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 10;

  const connectSocket = (userId) => {
    const storedUserId = userId || localStorage.getItem("id");

    if (
      storedUserId &&
      (!socket.current || socket.current.readyState !== WebSocket.OPEN)
    ) {
      socket.current = new WebSocket(
        `wss://tiki2p6i7j.execute-api.us-east-1.amazonaws.com/production/`,
      );

      socket.current.onopen = () => {
        reconnectAttempts.current = 0;
        socket.current.send(
          JSON.stringify({ action: "addUser", userId: Number(storedUserId) }),
        );
      };

      socket.current.onclose = () => {
        handleReconnect(storedUserId);
      };

      socket.current.onerror = (error) => {
        socket.current.close();
      };

      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // Notificar a todos los listeners registrados
        listeners.current.forEach((callback) => callback(data));
      };
    }
  };

  const handleReconnect = (userId) => {
    if (reconnectAttempts.current < maxReconnectAttempts) {
      setTimeout(() => {
        reconnectAttempts.current += 1;
        connectSocket(userId);
      }, 1000 * reconnectAttempts.current);
    } else {
      console.error("Máximos intentos de reconexión alcanzados");
    }
  };

  const disconnectSocket = () => {
    if (socket.current) {
      socket.current.onclose = null;
      socket.current.close();
      socket.current = null;
    }
  };

  const addMessageListener = (callback) => {
    listeners.current.push(callback);
  };

  const removeMessageListener = (callback) => {
    listeners.current = listeners.current.filter((cb) => cb !== callback);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("id");
    if (storedUserId) connectSocket(storedUserId);

    return () => {
      disconnectSocket();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectSocket,
        disconnectSocket,
        addMessageListener,
        removeMessageListener,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
