import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function addMemberToGroup(roomId, memberId) {
  const id = localStorage.getItem("id").toString();
  const accessToken = localStorage.getItem("currentToken");
  const response = await fetch(`${URL}/chats/addMembers/${id}/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      roomId: roomId,
      memberId: memberId,
    }),
  });
  if (checkResponse(response)) {
    return response;
  }
}
