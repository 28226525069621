import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function createNewMedicine(accessToken, data, file) {
  try {
    // Crear un FormData para incluir el archivo y los demás datos
    const formData = new FormData();

    // Adjuntar los datos del medicamento
    for (const key in data) {
      formData.append(key, data[key]);
    }

    // Adjuntar el archivo, si existe
    if (file) {
      formData.append("file", file); // La clave "file" debe coincidir con la esperada por el backend
    }

    // Realizar la solicitud
    const fetchResponse = await fetch(`${URL}/medicines/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${accessToken}`, // Sólo el token, no debes incluir Content-Type, ya que FormData lo configura automáticamente
      },
      body: formData,
    });

    const responseData = await fetchResponse.json();

    if (checkResponse(fetchResponse)) {
      return responseData;
    } else {
      console.error(
        `Request failed with status ${fetchResponse.status}: ${responseData.message || "Unknown error"}`,
      );
      return null;
    }
  } catch (error) {
    console.error("Error in createNewMedicine:", error);
    return null;
  }
}
