import React, { createContext, useContext, useState } from "react";
import ConfirmDialog from "../components/ConfirmDialog/ConfirmDialog";

const ConfirmContext = createContext();

export const ConfirmProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    title: "",
    message: "",
    confirmText: "Confirmar",
    cancelText: "Cancelar",
  });

  const [resolveCallback, setResolveCallback] = useState(null);

  const confirm = ({
    title = "¿Estás seguro?",
    message = "",
    confirmText = "Confirmar",
    cancelText = "Cancelar",
  }) => {
    return new Promise((resolve) => {
      setDialogState({
        open: true,
        title,
        message,
        confirmText,
        cancelText,
      });
      setResolveCallback(() => resolve);
    });
  };

  const handleConfirm = () => {
    resolveCallback(true);
    setDialogState({ ...dialogState, open: false });
  };

  const handleCancel = () => {
    resolveCallback(false);
    setDialogState({ ...dialogState, open: false });
  };

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <ConfirmDialog
        open={dialogState.open}
        title={dialogState.title}
        message={dialogState.message}
        confirmText={dialogState.confirmText}
        cancelText={dialogState.cancelText}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  return context;
};
