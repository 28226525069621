import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function activateUser(patient_id, accessToken) {
  const response = await fetch(
    `${URL}/users/${patient_id}/patient_reactivate/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    },
  );
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
