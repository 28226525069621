import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { postSummary } from "utils/post";
import { Typography, Grid } from "@mui/material";

const PREFIX = "Overview";

const classes = {
  root: `${PREFIX}-root`,
  valueContainer: `${PREFIX}-valueContainer`,
  numberBox: `${PREFIX}-numberBox`,
  numberText: `${PREFIX}-numberText`,
  alertsText: `${PREFIX}-alertsText`,
  backgroundContainer: `${PREFIX}-backgroundContainer`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.valueContainer}`]: {
    display: "flex",
    alignItems: "center", // Alinea los elementos verticalmente al centro
  },
  [`& .${classes.backgroundContainer}`]: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "24px",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
  [`& .${classes.numberBox}`]: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "18px",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  [`& .${classes.numberText}`]: {
    fontSize: "2.5rem",
    fontWeight: "600",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.alertsText}`]: {
    fontSize: "1.1rem",
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
}));

const Overview = ({ className, ...rest }) => {
  const [lm, setLm] = useState("");
  const [lw, setLw] = useState("");
  const [num, setNum] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("currentToken");
    const id = localStorage.getItem("id");

    async function fetchData() {
      try {
        const monthData = await postSummary(accessToken, id, "last-month");
        setLm(monthData.stats.alerts);
        const weekData = await postSummary(accessToken, id, "last-week");
        setLw(weekData.stats.alerts);
        setNum(weekData.stats.patients);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <StyledGrid
      container
      className={clsx(classes.root, className)}
      spacing={3}
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={4} md={4}>
        <div className={classes.backgroundContainer}>
          <div className={classes.valueContainer}>
            <div className={classes.numberBox}>
              <Typography className={classes.numberText}>{lw}</Typography>
            </div>
            <Typography className={classes.alertsText}>
              Alertas de la semana
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <div className={classes.backgroundContainer}>
          <div className={classes.valueContainer}>
            <div className={classes.numberBox}>
              <Typography className={classes.numberText}>{lm}</Typography>
            </div>
            <Typography className={classes.alertsText}>
              Alertas del mes
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <div className={classes.backgroundContainer}>
          <div className={classes.valueContainer}>
            <div className={classes.numberBox}>
              <Typography className={classes.numberText}>{num}</Typography>
            </div>
            <Typography className={classes.alertsText}>
              Total Pacientes
            </Typography>
          </div>
        </div>
      </Grid>
    </StyledGrid>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
