import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getPatientExams(patientID) {
  try {
    const accessToken = localStorage.getItem("currentToken"); // Obtener el token del localStorage

    const response = await fetch(`${URL}/medicalExams/patient/${patientID}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`, // Agregar el token al encabezado
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();

    if (checkResponse(response)) {
      return res; // Retorna los exámenes del paciente si es exitosa
    } else {
      throw new Error(
        `Error al obtener los exámenes del paciente: ${res.message || "Error desconocido"}`,
      );
    }
  } catch (error) {
    console.error("Error en getPatientExams:", error);
    return null; // Retorna null en caso de error
  }
}
