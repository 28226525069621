import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "./theme";
import { configureStore } from "./store";
import Routes from "./routes";
import { ScrollReset } from "./components";
import SocketContextProvider from "context/socketContext";
import ChatbotLauncher from "./views/Chatbot/ChatbotLauncher"; // Importamos el launcher del chatbot
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./assets/scss/index.scss";
import { ErrorBoundary } from "react-error-boundary";
import { AlertProvider } from "./contexts/AlertContext";
import { ConfirmProvider } from "./contexts/ConfirmContext";

const store = configureStore();
const history = createBrowserHistory();

const App = () => {
  return (
    <AlertProvider>
      <ConfirmProvider>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SocketContextProvider>
                <ErrorBoundary fallback={<div>Algo salió mal</div>}>
                  <Router history={history}>
                    <ScrollReset />
                    <Routes />
                    <ChatbotLauncher />
                  </Router>
                </ErrorBoundary>
              </SocketContextProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StoreProvider>
      </ConfirmProvider>
    </AlertProvider>
  );
};

export default App;
