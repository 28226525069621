import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getPatientTasks(patient_id) {
  const accessToken = localStorage.getItem("currentToken");

  let response;
  if (patient_id) {
    response = await fetch(`${URL}/users/${patient_id}/patient-tasks/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    });
  } else {
    response = await fetch(`${URL}/patientTasks/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    });
  }

  const res = await response.json();
  if (checkResponse(response)) {
    return { res, response };
  }
}
