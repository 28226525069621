import React, { useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  Paper,
  Avatar,
  Typography,
  Box,
  Button,
  Divider,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import useRouter from "utils/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";
import { logOut } from "actions";
import { useHistory } from "react-router";
import { useConfirm } from "contexts/ConfirmContext";

const PREFIX = "NavBar";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  profile: `${PREFIX}-profile`,
  avatar: `${PREFIX}-avatar`,
  name: `${PREFIX}-name`,
  divider: `${PREFIX}-divider`,
  navigation: `${PREFIX}-navigation`,
  logoutButton: `${PREFIX}-logoutButton`,
  logoutIcon: `${PREFIX}-logoutIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: "100%",
    overflowY: "auto",
  },
  [`& .${classes.rootMobile}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.content}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [`& .${classes.profile}`]: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
  },
  [`& .${classes.name}`]: {
    marginTop: theme.spacing(1),
  },
  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.navigation}`]: {
    margin: `${theme.spacing(1)}px 0`,
  },
  [`& .${classes.logoutButton}`]: {
    color: "#000",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.logoutIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const drawerClasses = {
  root: `${PREFIX}-drawer-root`,
  paper: `${PREFIX}-drawer-paper`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.root}`]: {
    padding: theme.spacing(3),
    minWidth: 280,
    "& .NavBar-profile": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "fit-content",
    },
  },
  [`& .${drawerClasses.paper}`]: {
    width: 280,
    backgroundColor: theme.palette.background.default, // Fondo personalizado
    borderRight: `1px solid ${theme.palette.divider}`, // Línea divisoria
  },
}));

const NavBar = ({ openMobile, onMobileClose, className, ...rest }) => {
  const { confirm } = useConfirm();
  const router = useRouter();
  const role = localStorage.getItem("role");
  const userData = useSelector((state) => state.userData.serverUserData);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    const confirmed = await confirm({
      title: "Cerrar Sesión",
      message: "¿Estás seguro/a que deseas cerrar sesión?",
      confirmText: "Sí, cerrar sesión",
      cancelText: "No, cancelar",
    });

    if (confirmed) {
      // 1. Guardar los datos de correo, contraseña y rememberMe antes de limpiar el localStorage
      const email = localStorage.getItem("email");
      const password = localStorage.getItem("password");
      const rememberMe = localStorage.getItem("rememberMe") === "true";

      // 2. Limpiar todo el localStorage
      localStorage.clear();

      // 3. Si rememberMe estaba activado, volvemos a asignar los datos
      if (rememberMe) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("rememberMe", true);
      }

      // 4. Despachar la acción de cerrar sesión
      dispatch(logOut());

      // 5. Redirigir al usuario a la página de login
      history.push("/auth/login");
    }
  };

  // Optimización de useEffect usando useCallback
  const handleMobileClose = useCallback(() => {
    if (openMobile && onMobileClose) {
      // onMobileClose();
    }
  }, [openMobile, onMobileClose]);

  useEffect(() => {
    handleMobileClose();
  }, [router.location.pathname, handleMobileClose]);

  const navigationOptions = navigationConfig[role];

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={userData.avatar}
          to={"/account"}
        />
        <Typography className={classes.name} variant="h4">
          {userData.first_name} {userData.last_name}
        </Typography>
        <Typography variant="body2">
          {role === "patient"
            ? userData.rut
            : role === "professional"
              ? userData.profile?.specialty
              : "Administrador"}
        </Typography>
      </div>
      <nav className={classes.navigation}>
        <Navigation component="div" pages={navigationOptions.pages} />
      </nav>
      <Divider className={classes.divider} />
      <Button
        variant="text"
        className={classes.logoutButton}
        onClick={handleLogout}
      >
        <Box>Cerrar Sesión</Box>
        <LogoutIcon className={classes.logoutIcon} />
      </Button>
    </div>
  );

  return (
    <Root>
      <Box sx={{ display: { lg: "none" } }}>
        <StyledDrawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          ModalProps={{
            keepMounted: true, // Mejora el rendimiento en móviles
          }}
        >
          <div {...rest} className={clsx(drawerClasses.root)}>
            {navbarContent}
          </div>
        </StyledDrawer>
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Box>
    </Root>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
