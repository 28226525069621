import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { green, red } from "@mui/material/colors";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { colors } from "@mui/material";

import { getTasks } from "utils/fetch";
import { actualizarTareas } from "utils/patch";
import { useAlert } from "contexts/AlertContext";

const PREFIX = "Todos";

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  content: `${PREFIX}-content`,
  opcion_espeficica: `${PREFIX}-opcion_espeficica`,
  addIcon: `${PREFIX}-addIcon`,
  eliminar_opcion: `${PREFIX}-eliminar_opcion`,
  contenedor_martin: `${PREFIX}-contenedor_martin`,
  textoNuevaOpcion: `${PREFIX}-textoNuevaOpcion`,
  optionRadio: `${PREFIX}-optionRadio`,
  done: `${PREFIX}-done`,
  buttonStyle: `${PREFIX}-buttonStyle`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.root3}`]: {},

  [`& .${classes.content}`]: {
    padding: 0,
  },

  [`& .${classes.opcion_espeficica}`]: {
    marginLeft: theme.spacing(2.7),
    marginTop: theme.spacing(0.5),
  },

  [`& .${classes.addIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.eliminar_opcion}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.contenedor_martin}`]: {
    display: "inline-flex",
    width: theme.spacing(100),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.textoNuevaOpcion}`]: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.optionRadio}`]: {
    marginTop: theme.spacing(1.3),
    marginLeft: theme.spacing(0.5),
  },

  [`& .${classes.done}`]: {
    color: "#bdbdbd",
  },

  [`& .${classes.buttonStyle}`]: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    "&:hover": {
      backgroundColor: colors.blue[900],
    },
  },
}));

const ColorfulSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: red[500],
    "&.Mui-checked": {
      color: green[500],
      "& + .MuiSwitch-track": {
        backgroundColor: green[500],
      },
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: red[500],
  },
}));

export const Todos = (props) => {
  const accessToken = localStorage.getItem("currentToken");
  const patientId = localStorage.getItem("id");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useAlert();

  useEffect(() => {
    async function fetchTasks() {
      setLoading(true);
      const { res } = await getTasks(patientId);
      setTasks(res.tasks);
      setLoading(false);
    }
    fetchTasks();
  }, [accessToken, patientId]);

  const handleSwitchChange = (task) => async (event) => {
    const updatedTask = { ...task, done: event.target.checked };

    try {
      const response = await actualizarTareas(updatedTask);
      if (response) {
        const updatedTasks = tasks.map((t) =>
          t.id === updatedTask.id ? updatedTask : t,
        );
        setTasks(updatedTasks);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      showError(
        "Ha ocurrido un problema con tu solicitud. Por favor, intente nuevamente.",
      );
    }
  };

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        title={<Typography variant="h3">Tareas Asignadas</Typography>}
        avatar={
          <LocalHospitalIcon
            fontSize="large"
            className={classes.cardIconStyle}
          />
        }
      />
      <Divider />
      <CardContent>
        {loading ? (
          <Typography variant="body1">Cargando...</Typography>
        ) : tasks.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Prioridad</TableCell>
                  <TableCell>Completado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task) => (
                  <TableRow key={task.id}>
                    <TableCell>{task.name}</TableCell>
                    <TableCell>{task.description}</TableCell>
                    <TableCell>
                      {task.priority === 1
                        ? "Baja"
                        : task.priority === 2
                          ? "Media"
                          : "Alta"}
                    </TableCell>
                    <TableCell>
                      <ColorfulSwitch
                        checked={task.done}
                        onChange={handleSwitchChange(task)}
                        color="primary"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">No hay tareas asignadas.</Typography>
        )}
      </CardContent>
    </StyledCard>
  );
};

Todos.propTypes = {
  className: PropTypes.string,
};

export default Todos;
