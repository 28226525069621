import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function changePassword(
  pass1,
  pass2,
  pass3,
  accessToken,
  user_id,
) {
  const response = await fetch(`${URL}/users/${user_id}/change-password/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      password: pass1,
      new_password: pass2,
      confirm_new_password: pass3,
    }),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
