import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Page } from "components";
import { Header } from "../NewQuestionary/components";
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NewQuestionCard from "../NewQuestionary/components/NewQuestionCard";
import { useHistory, useParams } from "react-router-dom";
import {
  editarCuestionario,
  editarEspecialidadDeCuestionario,
} from "../../utils/patch";
import { URL } from "../../utils/url";
import { useAlert } from "contexts/AlertContext";
import { SpecialtyInput } from "../NewQuestionary/components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";

const PREFIX = "EditQuestionary";

const classes = {
  root: `${PREFIX}-root`,
  mainContainer: `${PREFIX}-mainContainer`,
  item: `${PREFIX}-item`,
  questionCard: `${PREFIX}-questionCard`,
  textField: `${PREFIX}-textField`,
  answerCard: `${PREFIX}-answerCard`,
  customTextField: `${PREFIX}-customTextField`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  backButton: `${PREFIX}-backButton`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3),
  },

  [`& .${classes.mainContainer}`]: {
    maxWidth: "1000px",
    margin: "0 auto",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.questionCard}`]: {
    backgroundColor: "white",
    borderRadius: "12px",
    padding: theme.spacing(3),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
  },

  [`& .${classes.textField}`]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },

  [`& .${classes.answerCard}`]: {
    backgroundColor: theme.palette.background.white,
    borderRadius: "24px",
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.customTextField}`]: {
    "& .MuiInputLabel-root": {
      color: "#A1A1A1",
      fontSize: "1.2rem",
      fontWeight: 500,
      transform: "translate(14px, 16px) scale(1)",
      "&.Mui-focused, &.MuiFormLabel-filled": {
        transform: "translate(14px, -9px) scale(0.75)",
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.default,
      borderRadius: "8px",
      height: "56px",
      fontSize: "1.1rem",
      "&:hover fieldset": {
        borderColor: "#A1A1A1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#A1A1A1",
      },
    },
  },

  [`& .${classes.buttonContainer}`]: {
    paddingTop: theme.spacing(1),
    "& .MuiButton-root": {
      borderRadius: "16px",
    },
  },

  [`& .${classes.item}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.backButton}`]: {
    backgroundColor: theme.palette.bluePrimary.main,
    color: theme.palette.common.white,
    fontFamily: "Inter",
    fontWeight: 500,
    height: 44,
    fontSize: 16,
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    gap: theme.spacing(1),
    borderRadius: 16,
    textTransform: "none",
    transition: "filter 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.bluePrimary.main,
      filter: "brightness(1.1)",
    },
  },
}));

const EditQuestionary = () => {
  const { id } = useParams();
  const history = useHistory();
  const { showError, showSuccess } = useAlert();

  const [questions, setQuestions] = useState([]);
  const [name, setName] = useState("");
  const [specialtyId, setSpecialtyId] = useState([]);

  useEffect(() => {
    const fetchQuestionary = async () => {
      try {
        const response = await fetch(`${URL}/questionaries/${id}/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("currentToken")}`,
          },
        });
        const data = await response.json();

        setName(data.questionary_name);
        setQuestions(data.questions);
        setSpecialtyId(data.specialty.id);
      } catch (error) {
        console.error("Error fetching questionary:", error);
      }
    };

    fetchQuestionary();
  }, [id]);

  function addQuestion() {
    const newQuestion = {
      text: "",
      value: "text",
      options: [],
    };
    setQuestions([...questions, newQuestion]);
  }

  function updateQuestion(index, newQuestion) {
    const newQuestions = [...questions];
    newQuestions[index] = newQuestion;
    setQuestions(newQuestions);
  }

  function deleteQuestion(index) {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  }

  async function saveQuestionary() {
    if (name === "") {
      showError("Debe asignar un nombre al cuestionario");
      return;
    }

    if (questions.length === 0) {
      showError("Debe agregar al menos una pregunta");
      return;
    }

    if (!specialtyId) {
      showError("Debe seleccionar una especialidad");
      return;
    }

    for (let question of questions) {
      if (question.text === "") {
        showError("Cada pregunta debe tener un texto asociado.");
        return;
      }

      if (question.value === "options") {
        if (!question.options || question.options.length === 0) {
          showError("Las preguntas de opción múltiple deben tener opciones.");
          return;
        }

        if (Array.isArray(question.options)) {
          for (let option of question.options) {
            if (option === "") {
              showError("Cada opción debe tener un texto asociado.");
              return;
            }
          }
        }
      }
    }

    const questionary = {
      questionary_name: name,
      questions,
    };

    try {
      // Intenta actualizar el cuestionario
      await editarCuestionario(id, questionary);

      try {
        // Intenta actualizar la especialidad
        await editarEspecialidadDeCuestionario(id, specialtyId);

        // Si ambos pasos tienen éxito, navega y muestra el mensaje de éxito
        history.push("/cuestionariosdoctor");
        showSuccess("Cuestionario actualizado correctamente");
      } catch (errorEspecialidad) {
        console.error("Error updating specialty:", errorEspecialidad);

        // Si falla la actualización de la especialidad, revierte la primera operación (si aplica)
        showError(
          "Error al actualizar la especialidad del cuestionario. Operación cancelada.",
        );

        // Podrías implementar una lógica para revertir el `editarCuestionario` si es necesario.
        // Por ejemplo:
        // await revertirEditarCuestionario(id, previousQuestionaryData);
      }
    } catch (errorCuestionario) {
      console.error("Error updating questionary:", errorCuestionario);
      showError("Error al actualizar el cuestionario");
    }
  }

  return (
    <StyledPage title="Editar Cuestionario" className={classes.root}>
      <IconButton
        className={classes.backButton}
        size="large"
        component={RouterLink} // RouterLink para redirección
        to="/cuestionariosdoctor" // Redirige a cuestionariosdoctor
      >
        <ArrowBackIcon />
        Volver
      </IconButton>
      <Header />
      <div className={classes.mainContainer}>
        <Card className={classes.answerCard}>
          <CardContent>
            <TextField
              id="questionaryName"
              label="Nombre del cuestionario"
              variant="outlined"
              fullWidth
              color="secondary"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classes.customTextField}
            />
          </CardContent>
        </Card>

        <SpecialtyInput
          onSpecialtyChange={setSpecialtyId}
          initialSpecialty={specialtyId}
        />

        {questions.map((question, index) => (
          <div key={index} className={classes.item}>
            <NewQuestionCard
              question={question}
              questionIdx={index}
              deleteQuestion={deleteQuestion}
              updateQuestion={updateQuestion}
            />
          </div>
        ))}

        <Grid
          container
          spacing={3}
          className={`${classes.item} ${classes.buttonContainer}`}
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              startIcon={<NoteAddIcon />}
              onClick={addQuestion}
            >
              Agregar Pregunta
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              onClick={saveQuestionary}
            >
              Guardar Cambios
            </Button>
          </Grid>
        </Grid>
      </div>
    </StyledPage>
  );
};

export default EditQuestionary;
