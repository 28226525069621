// Function to handle patient request
import { URL } from "../url";

export async function handlePatientRequest(
  userID,
  patientID,
  newState,
  accessToken,
) {
  try {
    const response = await fetch(`${URL}/users/${userID}/update-request`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
      body: JSON.stringify({ patientID, state: newState }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Return the response data
  } catch (error) {
    console.error("Failed to update patient request:", error);
    throw error; // Propagate the error to the caller
  }
}
