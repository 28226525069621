import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, Grid } from "@mui/material";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  dates: `${PREFIX}-dates`,
  calendarTodayIcon: `${PREFIX}-calendarTodayIcon`,
};

const Header = (props) => {
  const { className, ...rest } = props;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="space-between" spacing={3}>
        <Grid item lg={6} xs={12}>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          ></Typography>
        </Grid>
        <Grid className={classes.dates} item lg={6} xs={12}></Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
