import { URL } from "../url";
import { checkResponse } from "../checkResponse";

/**
 * Crea un examen médico.
 *
 * @param {Object} examData - Los datos del examen médico.
 * @param {File} examData.file - El archivo asociado al examen.
 * @param {number} examData.patientID - El ID del paciente.
 * @param {string} examData.indications - Las indicaciones asociadas al examen.
 * @param {string} examData.name - El nombre del examen.
 * @param {boolean} examData.favorite - Si el examen es favorito o no.
 * @param {string} examData.fileType - El tipo de archivo ('pdf', 'imagen', 'otro').
 * @returns {Object|null} - La respuesta del servidor en caso de éxito, o null en caso de error.
 */
export async function createExam(examData) {
  const { patientID, indications, name, favorite, fileType } = examData; // Desestructuramos para mayor claridad

  const accessToken = localStorage.getItem("currentToken");
  try {
    const response = await fetch(`${URL}/medicalExams`, {
      method: "POST",
      headers: {
        Authorization: `Token ${accessToken}`,
        "Content-Type": "application/json", // El body es JSON
      },
      body: JSON.stringify({
        // file: file,
        patientID: patientID,
        indications: indications,
        name: name,
        favorite: favorite,
        fileType: fileType,
      }),
    });

    const res = await response.json();

    if (checkResponse(response)) {
      return res; // Retorna la respuesta en caso de éxito
    } else {
      throw new Error("Error en la creación del examen");
    }
  } catch (error) {
    console.error("Error en createExam:", error);
    return null; // Manejo de error, devuelve null en caso de fallo
  }
}
