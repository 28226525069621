import React, { useState, useEffect, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { green, red } from "@mui/material/colors";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Pagination,
  Box,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getTasks } from "utils/fetch";
import { actualizarTareas } from "utils/patch";
import TaskImage from "../../../../assets/icons/TaskImage.svg";
import { useAlert } from "contexts/AlertContext";

const LargeCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: "2.5rem",
  cursor: "pointer",
}));

const PREFIX = "Overview-CardTasks";

const classes = {
  // Estructura general y raíz
  root: `${PREFIX}-root`,
  contentWrapper: `${PREFIX}-contentWrapper`,

  // Encabezado del componente
  cardHeader: `${PREFIX}-cardHeader`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,

  // Imagen y contenedores visuales
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,

  // Detalles de la tarea
  cardWrapper: `${PREFIX}-cardWrapper`,
  cardContent: `${PREFIX}-cardContent`,
  taskName: `${PREFIX}-taskName`,
  priorityLabel: `${PREFIX}-priorityLabel`,

  // Etiquetas
  tagsWrapper: `${PREFIX}-tagsWrapper`,
  tag: `${PREFIX}-tag`,

  // Iconos de estado de tarea
  completionIcon: `${PREFIX}-completionIcon`,
  completedIcon: `${PREFIX}-completedIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {},
  [`& .${classes.contentWrapper}`]: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },

  // Encabezado del componente
  [`& .${classes.cardHeader}`]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontSize: "18px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0),
  },
  [`& .${classes.subtitle}`]: {
    fontSize: "16px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: 0,
  },

  // Imagen y contenedores visuales
  [`& .${classes.imageWrapper}`]: {
    height: "30%",
    backgroundColor: "#f0f0f0",
    borderRadius: "24px 24px 0 0",
    overflow: "hidden",
  },
  [`& .${classes.image}`]: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  // Detalles de la tarea
  [`& .${classes.cardWrapper}`]: {
    height: "100%",
    overflow: "visible",
  },
  [`& .${classes.cardContent}`]: {},
  [`& .${classes.taskName}`]: {
    fontSize: "16px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.priorityLabel}`]: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    padding: theme.spacing(1),
    borderRadius: "12px",
    display: "inline-block",
    fontWeight: "500",
  },

  // Etiquetas
  [`& .${classes.tagsWrapper}`]: {
    display: "flex",
    flexWrap: "wrap", // Permitir que los chips se envuelvan en múltiples líneas
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: "100%", // Limitar el ancho al contenedor
  },

  [`& .${classes.tag}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontSize: "12px",
  },

  // Iconos de estado de tarea
  [`& .${classes.completionIcon}`]: {
    color: red[500],
    cursor: "pointer",
    position: "absolute",
    top: -16,
    right: -16,
    fontSize: "48px",
  },
  [`& .${classes.completedIcon}`]: {
    color: green[500],
  },
}));

const getPriorityColor = (theme, priority) => {
  switch (priority) {
    case 1:
      return theme.palette.success.light;
    case 2:
      return theme.palette.primary.light;
    case 3:
      return theme.palette.warning.light;
    default:
      return theme.palette.grey[500];
  }
};

const getPriorityFontColor = (theme, priority) => {
  switch (priority) {
    case 1:
      return theme.palette.success.dark;
    case 2:
      return theme.palette.primary.dark;
    case 3:
      return theme.palette.warning.dark;
    default:
      return theme.palette.grey[500];
  }
};

const CardTasks = (props) => {
  const rootClasses = props.rootClasses;
  const loading = props.loading;
  const setLoading = props.setLoading;

  const accessToken = localStorage.getItem("currentToken");
  const patientId = localStorage.getItem("id");
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const tasksPerPage = 2;
  const theme = useTheme();
  const { showError } = useAlert();

  const indexOfLastTask = page * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  const completedTasks = tasks.filter((task) => task.done).length;
  const totalTasks = tasks.length;

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      try {
        const { res } = await getTasks(patientId);
        if (res && res.tasks) {
          const sortedTasks = [...res.tasks].sort(
            (a, b) => b.priority - a.priority,
          );
          setTasks(sortedTasks);
        } else {
          setTasks([]);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setTasks([]);
      }
      setLoading(false);
    };
    fetchTasks();
  }, [accessToken, patientId]);

  const handleIconClick = useCallback(
    (task) => async () => {
      const updatedTask = { ...task, done: !task.done };
      try {
        const response = await actualizarTareas(updatedTask);
        if (response) {
          const updatedTasks = tasks.map((t) =>
            t.id === updatedTask.id ? updatedTask : t,
          );
          setTasks(updatedTasks);
        } else {
          throw new Error();
        }
      } catch (error) {
        console.error(error);
        showError(
          "Ha ocurrido un problema con tu solicitud. Por favor, intente nuevamente.",
        );
      }
    },
    [tasks, showError],
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Root className={classes.root}>
      <Card className={rootClasses.card}>
        <CardHeader
          title={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h3" className={classes.title}>
                Tareas Asignadas
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                sx={{ marginLeft: "auto" }}
              >
                {completedTasks}/{totalTasks} tareas completadas
              </Typography>
            </Box>
          }
          className={classes.cardHeader}
          sx={{
            padding: (theme) => theme.spacing(2),
          }}
        />
        <CardContent className={classes.contentWrapper}>
          {loading ? (
            <Typography variant="body1">Cargando...</Typography>
          ) : currentTasks.length > 0 ? (
            <Grid container spacing={3}>
              {currentTasks.map((task) => (
                <Grid item xs={12} sm={6} md={6} key={task.id}>
                  <Card className={`${classes.cardWrapper}`}>
                    <div style={{ position: "relative" }}>
                      <LargeCheckCircleIcon
                        onClick={handleIconClick(task)}
                        className={`${classes.completionIcon} ${task.done ? classes.completedIcon : ""}`}
                        aria-label={
                          task.done
                            ? "Marcar como no hecho"
                            : "Marcar como hecho"
                        }
                        aria-pressed={task.done}
                        title={
                          task.done
                            ? "Marcar como no hecho"
                            : "Marcar como hecho"
                        }
                      />
                    </div>
                    <div className={classes.imageWrapper}>
                      <img
                        src={TaskImage}
                        alt="Task"
                        className={classes.image}
                      />
                    </div>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h6" className={classes.taskName}>
                        {task.name}
                      </Typography>
                      <Box
                        className={classes.priorityLabel}
                        sx={{
                          backgroundColor: getPriorityColor(
                            theme,
                            task.priority,
                          ),
                          color: getPriorityFontColor(theme, task.priority),
                        }}
                      >
                        Prioridad:{" "}
                        {task.priority === 1
                          ? "Baja"
                          : task.priority === 2
                            ? "Media"
                            : "Alta"}
                      </Box>
                      <Box className={classes.tagsWrapper}>
                        {task.tags.map((tag) => (
                          <Chip
                            key={tag.id}
                            className={classes.tag}
                            label={tag.tag_name}
                            size="small"
                          />
                        ))}
                      </Box>
                      <Typography variant="body2">
                        {task.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">No hay tareas asignadas.</Typography>
          )}
          <Pagination
            count={Math.ceil(tasks.length / tasksPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              width: "100%",
            }}
          />
        </CardContent>
      </Card>
    </Root>
  );
};

CardTasks.propTypes = {
  rootClasses: PropTypes.object,
  setIsSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default CardTasks;
