import { getAlerts } from "./getAlerts";

export async function getCrisis(accessToken, showResolved = false) {
  try {
    const currentUserId = parseInt(localStorage.getItem("id"));
    const alerts = await getAlerts(accessToken, currentUserId);

    if (alerts) {
      const crisisMessages = alerts.filter(
        (message) =>
          message.alert === true &&
          message.receiverID === currentUserId &&
          message.read === showResolved &&
          message.senderID !== null,
      );
      return crisisMessages;
    }
    return [];
  } catch (error) {
    console.error("Error in getCrisis:", error);
    return [];
  }
}
