import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register the required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export const StatisticsChart = ({ data }) => {
  const chartRef = useRef(null); // Reference to the chart instance

  // Calculate the averages for Dolor and Sueño
  const avgDolor =
    data.reduce((acc, item) => acc + item.Dolor, 0) / data.length;
  const avgSueño =
    data.reduce((acc, item) => acc + item.Sueño, 0) / data.length;

  const chartData = {
    labels: ["Dolor", "Sueño"],
    datasets: [
      {
        data: [avgDolor, avgSueño], // Dolor and Sueño data
        borderRadius: 10,
        barPercentage: 0.8, // Adjust bar width for consistent display
        categoryPercentage: 0.8, // Adjust space between bars
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return null; // Prevent rendering error when chartArea is not ready

          // Create a gradient for 'Dolor'
          const gradientDolor = ctx.createLinearGradient(
            0,
            0,
            chartArea.right,
            0,
          );
          gradientDolor.addColorStop(0, "rgba(255,193,7,0.1)"); // Very transparent at the start
          gradientDolor.addColorStop(0.3, "rgba(255,193,7,0.4)"); // Gradually more opaque
          gradientDolor.addColorStop(0.6, "rgba(255,193,7,0.7)"); // More opacity in the middle
          gradientDolor.addColorStop(1, "rgba(184,134,11,1)"); // Fully opaque dark gold at the end

          // Create a gradient for 'Sueño'
          const gradientSueño = ctx.createLinearGradient(
            0,
            0,
            chartArea.right,
            0,
          );
          gradientSueño.addColorStop(0, "rgba(30,136,229,0.1)"); // Very transparent at the start
          gradientSueño.addColorStop(0.3, "rgba(30,136,229,0.4)"); // Gradually more opaque
          gradientSueño.addColorStop(0.6, "rgba(30,136,229,0.7)"); // More opacity in the middle
          gradientSueño.addColorStop(1, "rgba(0,51,102,1)"); // Fully opaque dark blue at the end

          return context.dataIndex === 0 ? gradientDolor : gradientSueño;
        },
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bars
    responsive: true,
    maintainAspectRatio: false, // Prevent odd stretching
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      datalabels: {
        display: true,
        color: (context) => {
          // Use solid colors matching the darker end of the gradients
          return context.dataIndex === 0
            ? "rgba(184,134,11,1)"
            : "rgba(0,51,102,1)";
        },
        anchor: "end",
        align: "end",
        formatter: (value) => {
          return Math.round(value); // Format values as integers
        },
        font: {
          weight: "bold", // Bold font for data values
          size: 24, // Larger font size for data values
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 10, // Assuming 10 is the maximum score
        grid: {
          display: false, // Hide grid lines for x-axis
        },
        ticks: {
          display: false, // Hide ticks on x-axis
        },
        border: {
          display: false, // Hide the x-axis line
        },
      },
      y: {
        grid: {
          display: false, // Hide grid lines for y-axis
        },
        ticks: {
          align: "center", // Center-align the y-axis labels
          crossAlign: "center", // Vertically center the y-axis labels
          font: {
            size: 24, // Larger font size for y-axis labels
            weight: "bold", // Bold font for y-axis labels
          },
          color: (context) => {
            // Use solid colors matching the darker end of the gradients
            return context.index === 0
              ? "rgba(184,134,11,1)"
              : "rgba(0,51,102,1)";
          },
          padding: 5, // Adjust padding between bars and labels
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
  };

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <Bar ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default StatisticsChart;
