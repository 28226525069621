import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import DashboardAnalytics from "./views/DashboardAnalytics"; // DashboardAnalyticsView
import OverviewView from "./views/Overview";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logIn, setUserData } from "./actions";
import { getCurrentUser } from "./utils/fetch";
import EditQuestionary from "./views/EditQuestionary/EditQuestionary";

const Routes = () => {
  const loggingIn = useSelector((state) => state.session.loggingIn);
  const [aparecen, setAparecen] = React.useState(false);
  const [cargandon, setCargandon] = React.useState(true);
  const [successn, setSuccessn] = React.useState(false);
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("currentToken");
  const id = localStorage.getItem("id");
  const dispatch = useDispatch();
  const docoptions = [
    {
      path: "/",
      exact: true,
      loggedIn: loggingIn,
      component: () => <Redirect to="/dashboards/analytics" />,
    },
    {
      path: "/auth",
      component: AuthLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: "/auth/login",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Login")),
        },
        {
          path: "/auth/register/:token",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Register")),
        },
        {
          path: "/auth/autentificar",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Authenticate")),
        },
        {
          path: "/auth/password",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Password")),
        },
        {
          component: () => <Redirect to="/dashboards/analytics" />,
        },
      ],
    },
    {
      path: "/errors",
      component: ErrorLayout,
      routes: [
        {
          path: "/errors/error-401",
          exact: true,
          component: lazy(() => import("views/Error401")),
        },
        {
          path: "/errors/error-404",
          exact: true,
          component: lazy(() => import("views/Error404")),
        },
        {
          path: "/errors/error-500",
          exact: true,
          component: lazy(() => import("views/Error500")),
        },
        {
          component: () => <Redirect to="/errors/error-404" />,
        },
      ],
    },
    {
      route: "*",
      component: DashboardLayout,
      loggedIn: loggingIn,
      aparecen: aparecen,
      cargandon: cargandon,
      successn: successn,
      setAparecen: setAparecen,
      setCargandon: setCargandon,
      setSuccessn: setSuccessn,
      routes: [
        {
          path: "/archivos",
          exact: true,
          setAparecen: setAparecen,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Articles")),
        },
        {
          path: "/archivos/:tab",
          exact: true,
          setAparecen: setAparecen,
          setCargandon: setCargandon,
          setSuccessn: setSuccessn,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Articles")),
        },
        {
          path: "/password",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Password")),
        },
        {
          path: "/dashboards/analytics",
          exact: true,
          loggedIn: loggingIn,
          component: DashboardAnalytics,
        },
        {
          path: "/inicio",
          exact: true,
          loggedIn: loggingIn,
          component: DashboardAnalytics,
        },
        // {
        //   path: "/mail",
        //   exact: true,
        //   loggedIn: loggingIn,
        //   component: lazy(() => import("views/Mail")),
        // },
        {
          path: "/specialties",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Specialties")),
        },
        {
          path: "/especialidades",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Specialties")),
        },
        {
          path: "/management/customers",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/PatientsList")),
        },
        {
          path: "/pacientes",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/PatientsList")),
        },
        {
          path: "/profile/:id/:tab?/:subtab?",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Profile")),
        },
        {
          path: "/pacientes/:id/:tab?/:subtab?",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Profile")),
        },
        {
          path: "/new-paciente",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/NewPatient")),
        },
        {
          path: "/new-tratante",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/newTratante")),
        },
        {
          path: "/autoreporte",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelfReport")),
        },
        {
          path: "/autorreporte",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelfReport")),
        },
        {
          path: "/cuestionario/:id_preguntas/:id_reporte",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Cuestionario")),
        },
        {
          path: "/lista-tags",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Tags")),
        },
        {
          path: "/tareasdoctor",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/TaskList")),
        },
        {
          path: "/tareas",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/TaskList")),
        },
        {
          path: "/tareas/:id",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelectTasks")),
        },
        {
          path: "/ver-report/:id/",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/VerCuestionario")),
        },
        {
          path: "/ver-log/:id/",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/VerAutoreporte")),
        },
        {
          path: "/new-cuestionario",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/NewQuestionary")),
        },
        {
          path: "/cuestionariosdoctor",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/QuestionaryList")),
        },
        {
          path: "/tags/:id",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelectTags")),
        },
        {
          path: "/reports/:id",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelectCuestionario")),
        },
        {
          path: "/account",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/DoctorAccount")),
        },
        {
          path: "/cuenta",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/DoctorAccount")),
        },
        {
          path: "/mensajeria",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Messaging")),
        },
        {
          path: "/editar-cuestionario/:id",
          exact: true,
          loggedIn: loggingIn,
          component: EditQuestionary,
        },
        {
          path: "/patients-crisis",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/PatientsCrisis")),
        },
        {
          path: "/crisis",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/PatientsCrisis")),
        },
        {
          component: () => <Redirect to="/dashboards/analytics" />,
        },
      ],
    },
  ];
  const options = [
    {
      path: "/",
      exact: true,
      loggedIn: loggingIn,
      component: () => <Redirect to="/inicio" />,
    },
    {
      path: "/auth",
      component: AuthLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: "/auth/login",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Login")),
        },
        {
          path: "/auth/register/:token",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Register")),
        },
        {
          path: "/auth/autentificar",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Authenticate")),
        },
        {
          path: "/auth/autenticar",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Authenticate")),
        },
        {
          path: "/auth/password",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Password")),
        },
        {
          component: () => <Redirect to="/errors/error-404" />,
        },
      ],
    },
    {
      path: "/errors",
      component: ErrorLayout,
      routes: [
        {
          path: "/errors/error-401",
          exact: true,
          component: lazy(() => import("views/Error401")),
        },
        {
          path: "/errors/error-404",
          exact: true,
          component: lazy(() => import("views/Error404")),
        },
        {
          path: "/errors/error-500",
          exact: true,
          component: lazy(() => import("views/Error500")),
        },
        {
          component: () => <Redirect to="/errors/error-404" />,
        },
      ],
    },
    {
      route: "*",
      component: DashboardLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: "/files",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Articles")),
        },
        {
          path: "/archivos",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Articles")),
        },
        {
          path: "/archivos/:tab",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Articles")),
        },
        {
          path: "/overview",
          exact: true,
          loggedIn: loggingIn,
          component: OverviewView,
        },
        {
          path: "/inicio",
          exact: true,
          loggedIn: loggingIn,
          component: OverviewView,
        },
        {
          path: "/profile/:id?/:tab?/:subtab?",
          loggedIn: loggingIn,
          component: lazy(() => import("views/Profile")),
        },
        {
          path: "/mi-diagnostico/:tab?/:subtab?",
          loggedIn: loggingIn,
          component: lazy(() => import("views/Profile")),
        },
        {
          path: "/selfreport",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelfReport")),
        },
        {
          path: "/autorreporte",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/SelfReport")),
        },
        {
          path: "/tareas",
          exact: true,
          loggedIn: loggingIn,
          // component: lazy(() => import("views/TaskList")),
        },
        {
          path: "/cuestionario/:id_preguntas/:id_reporte",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Cuestionario")),
        },
        {
          path: "/ver-report/:id/",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/VerCuestionario")),
        },
        {
          path: "/ver-log/:id/",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/VerAutoreporte")),
        },
        {
          path: "/questionaries",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/PatientQuestionaryList")),
        },
        {
          path: "/cuestionarios",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/PatientQuestionaryList")),
        },
        {
          path: "/account",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/DoctorAccount")),
        },
        {
          path: "/cuenta",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/DoctorAccount")),
        },
        {
          path: "/mensajeria",
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import("views/Messaging")),
        },
        {
          component: () => <Redirect to="/errors/error-404" />,
        },
      ],
    },
  ];
  const login = [
    {
      path: "*",
      component: AuthLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: "/auth/register/:token",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Register")),
        },
        {
          path: "/auth/autentificar",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Authenticate")),
        },
        {
          path: "/auth/password",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/Password")),
        },
        {
          path: "/auth/reset-password",
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import("views/ResetPassword")),
        },
        {
          loggedIn: loggingIn,
          component: lazy(() => import("views/Login")),
        },
      ],
    },
  ];
  if (loggingIn) {
    if (role === "patient") {
      return renderRoutes(options);
    } else {
      return renderRoutes(docoptions);
    }
  } else {
    if (token) {
      dispatch(logIn(token, role));
      getCurrentUser(token, id).then((user) => {
        dispatch(setUserData(user));
      });

      if (role === "patient") {
        return renderRoutes(options);
      } else {
        return renderRoutes(docoptions);
      }
    } else {
      return renderRoutes(login);
    }
  }
};

export default Routes;
