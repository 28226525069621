import { URL } from "../url";
import { checkResponse } from "../checkResponse";

/**
 * Envía un mensaje al chatbot.
 * @param {string} message - El contenido del mensaje para el chatbot.
 * @param {string} user_id - El ID del usuario que envía el mensaje.
 * @param {string} thread_id - El ID del hilo de conversación.
 * @param {string} service - El servicio que se quiere iniciar ("auto-reporte" o "embedding").
 * @param {string} accessToken - El token de acceso para autenticación.
 * @returns {object} La respuesta JSON de la API si la solicitud es exitosa.
 */
export async function sendMessageToChatBot(
  message,
  user_id,
  thread_id,
  service,
  accessToken,
) {
  try {
    const response = await fetch(`${URL}/chatbot/stream`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
      body: JSON.stringify({
        message,
        user_id,
        thread_id,
        service,
      }),
    });

    const res = await response.json();

    if (checkResponse(response)) {
      return res; // Retorna la respuesta si es exitosa
    } else {
      throw new Error(
        `Failed to send message: ${res.message || "Unknown error"}`,
      );
    }
  } catch (error) {
    console.error("Error in sendMessageToChatBot:", error);
    throw error;
  }
}
