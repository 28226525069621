import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardContent,
  colors,
  TextField,
  Typography,
} from "@mui/material";

const PREFIX = "QText";

const classes = {
  root: `${PREFIX}-root`,
  saveButton: `${PREFIX}-saveButton`,
  textField: `${PREFIX}-textField`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },

  [`& .${classes.saveButton}`]: {
    color: "theme.palette.white",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
}));

// Custom styled TextField
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: "#EDEDED",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.light,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.text.primary,
  },
  "& .MuiInputBase-input": {
    minHeight: "100px",
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const QText = (props) => {
  const { question, answer, questionNumber, answerQuestion, ...rest } = props;
  const { text } = question;

  const [textAnswer, setTextAnswer] = useState(answer || "");

  const handleFieldChange = (event) => {
    const { value } = event.target;
    setTextAnswer(value);
    answerQuestion(questionNumber, value);
  };

  return (
    <StyledCard {...rest} className={classes.root}>
      <CardHeader
        title={<StyledTypography>{text}</StyledTypography>}
        style={{ padding: 0, marginBottom: "12px" }}
      />
      <CardContent style={{ padding: 0 }}>
        <CustomTextField
          {...rest}
          fullWidth
          multiline
          helperText=""
          name="answer"
          onChange={handleFieldChange}
          required
          value={textAnswer}
          variant="outlined"
          color="primary"
          className={classes.textField}
        />
      </CardContent>
    </StyledCard>
  );
};

export default QText;
